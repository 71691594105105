.parents {
  max-width: 1920px;
  margin: 0 auto;
  background: #FCF8FF;
}

.parents__header {
  background: url(../../assets/images/parents_bg.webp);
  background-size: cover;
  background-position: center;
  padding: 50px 100px;
}

.parents__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100px;
}

.parents__nav > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.parents__nav p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #FFFFFF;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-right: 15px;
}

.parents__nav h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  color: #FFFFFF;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-right: 15px;
}

.parents__nav span {
  cursor: pointer;
}

.parents__nav img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.parents__nav button {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #007D3A;
  background: white;
}

.parents__nav button:hover {
  background: white;
}

.farzandlar {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px 90px;
  margin-top: 200px;
  padding-bottom: 100px;
}

.farzand {
  width: 100%;
  min-height: 200px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 5px 27px rgba(77, 3, 117, 0.2);
          box-shadow: 0px 5px 27px rgba(77, 3, 117, 0.2);
  border-radius: 14px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
}

.farzand .f_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  z-index: 2;
  height: 80px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.farzand .f_info div:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.farzand .f_info div:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.farzand .f_info div:nth-child(2) p {
  text-align: right;
}

.farzand .f_info img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.farzand .f_check {
  position: absolute;
  background: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.farzand p {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 100%;
  color: #000000;
  margin-bottom: 6px;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
  margin-left: 10px;
}

.farzand::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 90%;
  background: #F6E6FF;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 51.51%, 2% 50.62%, 4% 49.93%, 6% 49.46%, 8% 49.21%, 10% 49.18%, 12% 49.38%, 14% 49.79%, 16% 50.42%, 18% 51.26%, 20% 52.3%, 22% 53.52%, 24% 54.9%, 26% 56.44%, 28% 58.11%, 30% 59.89%, 32% 61.75%, 34% 63.68%, 36% 65.64%, 38% 67.62%, 40% 69.59%, 42% 71.51%, 44% 73.38%, 46% 75.16%, 48% 76.83%, 50% 78.38%, 52% 79.77%, 54% 80.99%, 56% 82.04%, 58% 82.88%, 60% 83.52%, 62% 83.95%, 64% 84.15%, 66% 84.13%, 68% 83.88%, 70% 83.42%, 72% 82.74%, 74% 81.86%, 76% 80.78%, 78% 79.52%, 80% 78.1%, 82% 76.53%, 84% 74.84%, 86% 73.04%, 88% 71.16%, 90% 69.22%, 92% 67.25%, 94% 65.28%, 96% 63.32%, 98% 61.4%, 100% 59.55%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 51.51%, 2% 50.62%, 4% 49.93%, 6% 49.46%, 8% 49.21%, 10% 49.18%, 12% 49.38%, 14% 49.79%, 16% 50.42%, 18% 51.26%, 20% 52.3%, 22% 53.52%, 24% 54.9%, 26% 56.44%, 28% 58.11%, 30% 59.89%, 32% 61.75%, 34% 63.68%, 36% 65.64%, 38% 67.62%, 40% 69.59%, 42% 71.51%, 44% 73.38%, 46% 75.16%, 48% 76.83%, 50% 78.38%, 52% 79.77%, 54% 80.99%, 56% 82.04%, 58% 82.88%, 60% 83.52%, 62% 83.95%, 64% 84.15%, 66% 84.13%, 68% 83.88%, 70% 83.42%, 72% 82.74%, 74% 81.86%, 76% 80.78%, 78% 79.52%, 80% 78.1%, 82% 76.53%, 84% 74.84%, 86% 73.04%, 88% 71.16%, 90% 69.22%, 92% 67.25%, 94% 65.28%, 96% 63.32%, 98% 61.4%, 100% 59.55%);
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
}

.farzand .f_status {
  z-index: 2;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
}

.farzand .f_status span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: red;
  padding: 9px 7px;
  border-radius: 10px;
  background: rgba(77, 3, 117, 0.1);
  margin-left: 20px;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
  z-index: 10;
}

.farzand .f_status span path {
  fill: #4D0375;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
}

.farzand .ant-progress-inner {
  background: rgba(77, 3, 117, 0.5);
}

.farzand .anticon {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 20;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#f_active {
  background: #4D0375;
  -webkit-box-shadow: 0px 5px 23px rgba(77, 3, 117, 0.3);
          box-shadow: 0px 5px 23px rgba(77, 3, 117, 0.3);
}

#f_active p {
  color: white;
}

#f_active::before {
  background: #62188A;
}

#f_active .ant-progress-inner {
  background: rgba(255, 255, 255, 0.5);
}

#f_active .ant-progress-bg {
  background: white !important;
}

#f_active .f_status span {
  background: rgba(255, 255, 255, 0.1);
}

#f_active .f_status span path {
  fill: #FFFFFF;
}

#f_active .anticon svg {
  fill: white;
}

.f_results {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 100px;
  margin-top: 70px;
}

.f_results .table__div {
  padding: 40px 65px;
  background: #FFFFFF;
  border-radius: 13px;
  margin-bottom: 30px;
}

.f_results table {
  width: 100%;
  border-spacing: 5px;
}

.f_results .f_first {
  border-bottom: 2.6px solid #4D0375;
}

.f_results .f_first td {
  font-weight: 500;
  font-size: 26px;
  line-height: 42px;
}

.f_results tr {
  border-bottom: 1.3px solid rgba(77, 3, 117, 0.5);
}

.f_results td {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 115%;
  color: #000000;
  padding-bottom: 25px;
  padding-top: 20px;
  max-width: 300px;
}

@media (max-width: 1440px) {
  .farzandlar {
    grid-gap: 20px;
  }
  .parents__header {
    padding: 50px;
  }
  .farzand p {
    font-size: 18px;
  }
  .farzand .f_status span {
    margin-left: 30px;
  }
  .f_results {
    padding: 0 30px;
  }
  .f_results .table__div {
    padding: 30px 20px;
  }
  .f_results .f_first td {
    font-size: 24px;
  }
  .f_results td {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .farzandlar {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    margin-top: 100px;
  }
}

@media (max-width: 1024px) {
  .parents__header {
    padding: 50px;
  }
  .f_results .f_first td {
    font-size: 22px;
  }
  .f_results td {
    font-size: 16px;
  }
}

@media (max-width: 800px) {
  .farzandlar {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    margin-top: 40px;
  }
  .f_results {
    margin-top: 40px;
  }
  .f_results .table__div {
    padding: 20px;
  }
  .f_results .f_first td {
    font-size: 20px;
  }
  .f_results td {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .parents__nav h1 {
    font-size: 27px;
  }
  .parents__nav p {
    font-size: 16px;
  }
  .parents__nav button {
    font-size: 15px;
  }
  .parents__nav img {
    width: 50px;
    height: 50px;
  }
  .parents__header {
    padding: 20px 30px;
  }
}

@media (max-width: 650px) {
  .f_results .table__div {
    overflow: scroll hidden;
  }
  .f_results table {
    width: 150%;
  }
}

@media (max-width: 560px) {
  .parents__nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .parents__nav h1 {
    font-size: 25px;
  }
  .parents__nav p {
    font-size: 15px;
  }
  .parents__nav button {
    font-size: 14px;
  }
  .parents__nav > div:nth-child(1) {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
  }
  .parents__header {
    padding: 20px;
  }
}

@media (max-width: 425px) {
  .farzand {
    min-height: 180px;
    padding: 10px;
  }
  .farzand p {
    font-size: 16px;
  }
  .farzand .f_info {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
  }
  .farzand .f_info img {
    width: 50px;
    height: 50px;
  }
  .farzand .f_status span {
    margin-left: 30px;
  }
  .farzand .f_status span svg {
    width: 20px;
    height: 16px;
  }
  .f_results {
    padding: 0 10px;
  }
  .f_results table {
    width: 200%;
  }
  .f_results td {
    padding-top: 10px;
    padding-bottom: 15px;
    max-width: 250px;
  }
}

@media (max-width: 375px) {
  .f_results table {
    width: 250%;
  }
}
