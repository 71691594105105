*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lexend';
}
body {
  margin: 0px;
  overflow: visible !important;
  padding-right: 0 !important;
}

body::-webkit-scrollbar {
  width: 0.5em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgb(0, 66, 209);
  border-radius: 2px;
}
.ant-scrolling-effect{
  width: 100% !important;
}
.ant-back-top{
  z-index: 1000 !important;
}
.no__data{
  margin-top: 100px;
}
@media (max-width:768px) {
  .ant-scrolling-effect{
    width: 100% !important;
  }
}

.myaccordion{
  border: none !important;
  box-shadow: none !important;
}
.section__container .ant-affix{
  z-index: 0 !important;
}
.home__affix div,
.home__affix{
  transition: 0.6s linear;
}
/* .section__container{
  scroll-snap-type: y mandatory;
  transition: 0.6s linear;
} */
/* .section__container{
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  transition: 1s linear;
}
.nav__section{
  position: relative;
}
.sections{
  scroll-snap-align: start;
  transition: 1s linear;
}
.section__container::-webkit-scrollbar {
  width: 0em;
}
 
.section__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: white;
}
 
.section__container::-webkit-scrollbar-thumb {
  background-color: #545BFF;
  border-radius: 2px;
} */


.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Lexend";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 12px;
  font-weight: bold;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
/* @media (max-width:768px) {
  .home__affix .ant-affix {
    position: absolute !important;
  }
} */

.mainmain {
  
  background: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  box-shadow: 0px 0px 40px #c6d0de;
  position:relative !important;
  border-radius: 6px;
  padding: 10px;
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.sentdiv:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #ffffff;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  border-bottom: 10px solid transparent;
  right: -20px;
  top: 10px;
}
.receiveddiv:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #ffffff;
  border-top: 10px solid #ffffff;
  border-bottom: 10px solid transparent;
  left: -19px;
  top: 10px;
}
/* .dostonbek_select  .css-1s2u09g-control{
  height: 55px !important;
}
.dostonbek_select  .css-1pahdxg-control{
  height: 55px !important;
}
.dostonbek_select  .css-1okebmr-indicatorSeparator{
  display: none !important;
} */
.dostonbek__control{
  height: 55px !important;
}
.dostonbek__control{
  height: 55px ;
}
figure img{
  width: 100%;
}
