.gifts__container {
  background: url(../../assets/images/Gifts/gifts_bg.webp);
  background-position: center;
  background-size: cover;
  overflow: hidden;
  z-index: 103;
  position: relative;
}

.gifts__images1 {
  position: absolute;
  top: 50px;
  left: -50px;
}

.gifts__images2 {
  position: absolute;
  top: 50px;
  right: -50px;
}

.gifts__images3 {
  position: absolute;
  bottom: 50px;
  left: -150px;
}

.gifts__images4 {
  position: absolute;
  bottom: 50px;
  right: -150px;
}

.gifts {
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 24.8494px 49.6988px rgba(132, 59, 254, 0.2));
          filter: drop-shadow(0px 24.8494px 49.6988px rgba(132, 59, 254, 0.2));
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 1440px;
  position: relative;
}

.gifts .mySwiper2 {
  max-width: 768px;
  padding: 0 50px;
}

.gifts .gift__swiper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 50px;
}

.gifts .gift__swiper img {
  max-width: 768px;
  border-radius: 16px;
  width: 96%;
}

.gifts .swiper-button-next:after, .gifts .swiper-button-prev:after {
  font-size: 18px;
  background: #363CC7;
  width: 35px;
  height: 35px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 8px;
}

.gifts .swiper-button-next, .gifts .swiper-button-prev {
  width: 40px;
  height: 40px;
}

.gift__swiper2 img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.gifts__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

.gifts__title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  padding-bottom: 30px;
}

.gifts__block {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  max-width: 1440px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 100px;
}

.gifts__left img {
  width: 100%;
}

.gr_items {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.gr_items p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: left;
  color: #333333;
  padding: 60px 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.gr_items span {
  position: absolute;
  left: 0;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 115.244px;
  line-height: 144px;
  text-align: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#03F5FF), to(#02B6EB));
  background: linear-gradient(180deg, #03F5FF 0%, #02B6EB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  opacity: 0.3;
}

.gr_items a {
  width: 230px;
  height: 64px;
  background: -webkit-gradient(linear, left top, left bottom, from(#03F5FF), to(#02B6EB));
  background: linear-gradient(180deg, #03F5FF 0%, #02B6EB 100%);
  -webkit-box-shadow: 0px 8.02752px 16.055px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 8.02752px 16.055px rgba(93, 95, 239, 0.2);
  border-radius: 8.02752px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  color: #FFFFFF;
  border: none;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.gr_items a:hover {
  -webkit-transform: scale(1.02) !important;
          transform: scale(1.02) !important;
}

@media (max-width: 1440px) {
  .gifts {
    max-width: 100%;
  }
  .gifts__images1 {
    left: 0;
  }
  .gifts__images2 {
    right: 0;
  }
  .gifts__images3 {
    left: 0;
  }
  .gifts__images4 {
    right: 0;
  }
}

@media (max-width: 1260px) {
  .gifts__title {
    font-size: 29px;
  }
  .gr_items p {
    font-size: 20px;
    padding: 35px 0;
  }
  .gr_items a {
    width: 200px;
    height: 50px;
    font-size: 22px;
  }
}

@media (max-width: 1000px) {
  .gifts__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 0;
  }
  .gifts__left img {
    width: 100%;
  }
  .gr_items {
    justify-items: center;
  }
  .gr_items p {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .gifts {
    padding-top: 50px;
  }
  .gift__swiper img {
    width: 99%;
  }
  .gifts__images1, .gifts__images2,
  .gifts__images3, .gifts__images4 {
    width: 150px;
  }
}

@media (max-width: 610px) {
  .gifts__title {
    font-size: 24px;
    text-align: center;
  }
  .gr_items p {
    font-size: 18px;
  }
  .gr_items a {
    width: 160px;
    height: 40px;
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .gr_items {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .gr_items p {
    padding: 10px 0;
    display: block;
  }
  .gr_items span {
    font-size: 30px;
    position: relative;
    line-height: 33px;
  }
  .gifts__right {
    margin-top: 20px;
  }
  .gifts__images1, .gifts__images2,
  .gifts__images3, .gifts__images4 {
    display: none;
  }
  .gifts {
    padding-bottom: 0px;
  }
  .gifts .mySwiper2 {
    padding: 0 0 50px 0;
  }
  .gifts .swiper-button-next, .gifts .swiper-button-prev {
    top: auto;
    bottom: 30px;
  }
  .gifts .swiper-button-prev {
    left: 35%;
  }
  .gifts .swiper-button-next {
    right: 35%;
  }
}

@media (max-width: 320px) {
  .gifts .mySwiper {
    display: none;
  }
  .gift__swiper img {
    width: auto !important;
    height: 220px !important;
  }
}
