.saqlangan__wrapper {
  padding-top: 210px;
}

.saqlangan {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
}

.saqlangan h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 52px;
}

.saqlanganlar__list {
  border-bottom: 1px solid #9C9C9C;
  max-width: 840px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.saqlanganlar__list a {
  display: block;
  padding-bottom: 28px;
  margin-right: 58px;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  border-bottom: 3px solid transparent;
}

.saqlanganlar__list .link_Active1 {
  color: #EB7300;
  border-bottom: 3px solid #EB7300;
}

.saqlanganlar__list .link_Active2 {
  color: #0BA7BF;
  border-bottom: 3px solid #0BA7BF;
}

.saqlanganlar__list .link_Active3 {
  color: #0BA7BF;
  border-bottom: 3px solid #0BA7BF;
}

.saqlanganlar__list .link_Active4 {
  color: #1D794B;
  border-bottom: 3px solid #1D794B;
}

@media (max-width: 800px) {
  .saqlangan h2 {
    text-align: center;
  }
  .saqlangan .saqlanganlar__list {
    width: 100%;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .saqlangan .saqlanganlar__list a {
    margin: 10px;
  }
}

.barchasi__wrapper {
  padding-top: 32px;
}

.barchasi {
  height: 900px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.barchasi::-webkit-scrollbar {
  display: none;
}

.barchasi_btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 804px;
  padding: 10px 5px;
}

.barchasi_btns button,
.barchasi_btns select,
.barchasi_btns input {
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  padding: 9px 23px;
  outline: none;
}

.barchasi_btns button:hover,
.barchasi_btns select:hover,
.barchasi_btns input:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.587);
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.587);
}

.barchasi_btns button:active {
  -webkit-box-shadow: inset 0 0 5px #000;
          box-shadow: inset 0 0 5px #000;
}

.barchasi__list {
  list-style-type: none;
}

.barchasi__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 80px;
}

.barchasi__list .barchasi__list-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.barchasi__list .barchasi__modal {
  position: relative;
}

.barchasi__list .barchasi__modal .button__dot {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 30px;
}

.barchasi__list .barchasi__modal .barchasi__modal-item {
  position: absolute;
  right: 20px;
  width: 200px;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.541);
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.541);
  background-color: #fff;
  border-radius: 5px;
  font-size: 20px !important;
  padding: 10px 0px;
  text-align: center;
  display: none;
}

.barchasi__list .barchasi__modal .barchasi__modal-item div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.barchasi__list .barchasi__modal .barchasi__modal-item div input {
  -webkit-transform: scale(2);
          transform: scale(2);
  margin-right: 10px;
}

.barchasi__list .barchasi__modal .barchasi__modal-item div input:hover {
  cursor: pointer;
}

.barchasi__list .barchasi__modal .barchasi__modal-item div input:active {
  background-color: green;
}

.barchasi__list .barchasi__modal .barchasi__modal-item div label:hover {
  cursor: pointer;
}

.barchasi__list .barchasi__modal .barchasi__modal-item div label:active {
  color: green;
}

.barchasi__list .barchasi__modal .barchasi__modal-item button {
  padding: 5px;
  background: transparent;
  border: none;
}

.barchasi__list .barchasi__modal .barchasi__modal-item button:hover {
  cursor: pointer;
}

.barchasi__list .barchasi__modal .barchasi__modal-item button:active {
  color: red;
}

.barchasi__list .barchasi__modal .barchasi__modal-item-block {
  display: block !important;
}

.barchasi__video {
  position: relative;
}

.barchasi__video img {
  width: 352px;
  height: 176px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
}

.barchasi__video img:hover {
  cursor: pointer;
}

.barchasi__video::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-46px) translateY(-46px);
          transform: translateX(-46px) translateY(-46px);
  width: 92px;
  height: 92px;
  display: block;
  background-image: url("../../assets/images/Kabinet/saved-play.png");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.262);
}

.barchasi__video:hover::before {
  cursor: pointer;
}

.barchasi__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 23px;
}

.barchasi__info h3 {
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
}

.barchasi__info p {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.barchasi__info-mini {
  max-width: 321px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 860px) {
  .barchasi__list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
  }
  .barchasi__list li .barchasi__info h3 {
    font-size: 20px;
  }
  .barchasi__list li .barchasi__modal {
    position: absolute;
    left: center;
    top: 220px;
    -webkit-transform: translateX(140px);
            transform: translateX(140px);
  }
  .barchasi__list li .barchasi__modal button {
    cursor: pointer;
  }
  .barchasi__list .barchasi__list-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 600px) {
  .saqlangan {
    padding: 0;
  }
  .barchasi__list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
  }
  .barchasi__list li .barchasi__modal {
    position: absolute;
    left: center;
    top: 220px;
    -webkit-transform: translateX(140px);
            transform: translateX(140px);
  }
  .barchasi__list li .barchasi__modal button {
    cursor: pointer;
  }
  .barchasi__video img {
    max-width: 352px;
  }
}

@media (max-width: 800px) {
  .saqlanganlar__list {
    border: none;
  }
  .barchasi_btns {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .barchasi_btns button,
  .barchasi_btns select,
  .barchasi_btns input {
    margin-bottom: 10px;
  }
}

@media (max-width: 350px) {
  .barchasi__list li .barchasi__modal {
    position: absolute;
    left: center;
    top: 220px;
    -webkit-transform: translateX(90px);
            transform: translateX(90px);
  }
  .barchasi__list li .barchasi__modal button {
    cursor: pointer;
  }
  .barchasi__info h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }
}
