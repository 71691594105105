@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");
.mobile__app__container {
  background: linear-gradient(247.5deg, #7B33C4 17.34%, #23015B 77.9%);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 30px 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  z-index: 107;
  position: relative;
}

.mobile__app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 1640px;
  position: relative;
  padding-bottom: 30px;
}

.app__left {
  z-index: 1;
  margin-left: 20px;
}

.app__left h1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 41.9394px;
  line-height: 52px;
  color: #fff;
  margin-bottom: 30px;
}

.app__left p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 120px;
}

.app__left a {
  margin: 0 10px;
}

.app__left a img {
  width: 250px;
  height: 70px;
}

.app__right {
  z-index: 1;
}

.scuare {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: -ms-grid;
  display: grid;
  height: 500px;
  overflow: hidden;
  bottom: 0;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  border-radius: 75px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 20px -12px black;
          box-shadow: 0px 0px 20px -12px black;
}

.scuare div:nth-child(1) {
  background: radial-gradient(50% 50% at 50% 50%, rgba(182, 245, 0, 0.5) 0%, rgba(189, 255, 0, 0) 100%);
  -webkit-filter: blur(150px);
          filter: blur(150px);
  width: auto;
  height: 400px;
  -webkit-transform: translateX(-150px) translateY(-150px);
          transform: translateX(-150px) translateY(-150px);
}

.scuare div:nth-child(2) {
  background: radial-gradient(50% 50% at 50% 50%, rgba(241, 0, 245, 0.3) 0%, rgba(189, 0, 255, 0) 100%);
  -webkit-filter: blur(150px);
          filter: blur(150px);
  width: auto;
  height: 400px;
}

.scuare div:nth-child(3) {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 231, 245, 0.5) 0%, rgba(0, 255, 240, 0) 100%);
  -webkit-filter: blur(150px);
          filter: blur(150px);
  width: auto;
  height: 400px;
  -webkit-transform: translateX(-50px) translateY(50px);
          transform: translateX(-50px) translateY(50px);
}

@media (max-width: 1680px) {
  .mobile__app {
    margin: 0 30px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1610px) {
  .app__right img {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .mobile__app {
    -ms-grid-columns: 1fr 1.2fr;
        grid-template-columns: 1fr 1.2fr;
  }
}

@media (max-width: 1290px) {
  .app__left a img {
    width: 200px;
    height: auto;
  }
  .mobile__app::before {
    height: 450px;
  }
  .scuare {
    height: 450px;
  }
}

@media (max-width: 1075px) {
  .app__left h1 {
    font-size: 37px;
  }
  .app__left p {
    font-size: 25px;
    margin-bottom: 80px;
  }
}

@media (max-width: 1024px) {
  .mobile__app {
    margin: 0 20px;
    padding-top: 50px;
  }
  .app__left div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .app__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .app__right img {
    width: 100%;
  }
}

@media (max-width: 940px) {
  .app__left h1 {
    font-size: 33px;
    margin-bottom: 0;
  }
  .app__left p {
    font-size: 21px;
    margin-bottom: 80px;
  }
  .mobile__app {
    padding-top: 0;
  }
  .mobile__app::before {
    height: 330px;
  }
  .scuare {
    height: 330px;
  }
}

@media (max-width: 855px) {
  .app__left p {
    margin-bottom: 50px;
  }
  .app__left a img {
    width: 160px;
  }
}

@media (max-width: 768px) {
  .mobile__app {
    padding-top: 20px;
  }
  .app__left h1 {
    line-height: 30px;
    margin-bottom: 20px;
  }
  .app__left p {
    line-height: 20px;
    margin-bottom: 20px;
  }
  .app__left div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    grid-gap: 15px;
  }
}

@media (max-width: 660px) {
  .mobile__app {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    height: auto;
    width: 100%;
  }
  .mobile__app::before {
    height: 100%;
  }
  .app__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .app__left div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .app__left h1, .app__left p {
    text-align: center;
  }
  .scuare {
    height: 100%;
  }
}

@media (max-width: 425px) {
  .mobile__app__container {
    padding: 10px 0;
  }
  .mobile__app::before {
    border-radius: 40px;
    width: calc(100% - 40px);
    left: 20px;
  }
  .scuare {
    border-radius: 40px;
    width: calc(100% - 40px);
    left: 20px;
  }
  .app__left {
    margin-left: 0;
  }
  .app__left h1 {
    font-size: 28px;
  }
  .app__left p {
    font-size: 18px;
  }
  .app__left div {
    grid-gap: 0;
  }
  .app__left div a img {
    width: 120px;
  }
}
