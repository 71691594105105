@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");
@font-face {
  font-family: 'Constantia';
  src: url(../../assets/fonts/Constantia.ttf);
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.my__atropos1 {
  -webkit-transform: translate3d(0, 0, 0) translateY(30px) !important;
          transform: translate3d(0, 0, 0) translateY(30px) !important;
  margin: 5px;
}

.my__atropos2 {
  margin: 5px;
}

.my__atropos3 {
  -webkit-transform: translate3d(0, 0, 0) translateY(-30px) !important;
          transform: translate3d(0, 0, 0) translateY(-30px) !important;
  margin: 5px;
}

.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 150px;
  position: absolute;
  top: 0;
  z-index: 100000001;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  max-width: 1920px;
}

.nav__fixed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 1525px;
  width: 100%;
}

.logo a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.logo p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: 'Constantia';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #FFF;
  padding-top: 5px;
  text-transform: uppercase;
}

.logo p span {
  font-family: 'Constantia';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #FFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.logo p span::after, .logo p span::before {
  content: "";
  width: 20px;
  height: 5px;
  background: #FFF;
  display: block;
  margin: 0 2px;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 51%);
          clip-path: polygon(0 0, 0 100%, 100% 51%);
}

.logo p span::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

#all__logo {
  width: 80px;
  height: 80px;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-flex: 0.5;
      -ms-flex-positive: 0.5;
          flex-grow: 0.5;
}

.nav nav, .nav div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav nav a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #FFF;
  margin: 0 30px;
  padding-bottom: 7px;
}

.active {
  font-weight: 600 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#03F5FF), to(#02B6EB));
  background: linear-gradient(180deg, #03F5FF 0%, #02B6EB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
}

.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(#03F5FF), to(#02B6EB));
  background: linear-gradient(180deg, #03F5FF 0%, #02B6EB 100%);
  border-radius: 10px;
}

.sing__in__btn {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: -webkit-gradient(linear, left top, left bottom, from(#03F5FF), to(#02B6EB));
  background: linear-gradient(180deg, #03F5FF 0%, #02B6EB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  border-radius: 7px;
  border: 2px solid #03F5FF;
  padding: 10px 25px;
  margin-bottom: 7px;
  margin-right: 25px;
}

.sing__up__btn {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 7px;
  padding: 12px 27px;
  color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#03F5FF), to(#02B6EB));
  background: linear-gradient(180deg, #03F5FF 0%, #02B6EB 100%);
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.burger__btn {
  display: none;
}

.nav__open__notshow {
  z-index: -1;
}

@media (max-width: 1550px) {
  .nav__fixed {
    padding: 0 20px;
  }
}

@media (max-width: 1450px) {
  .nav__fixed {
    padding: 0 50px;
  }
}

@media (max-width: 1120px) {
  .nav nav a {
    margin: 0 10px;
  }
}

@media (max-width: 1050px) {
  .nav__fixed {
    padding: 0 30px;
  }
}

@media (max-width: 1254px) {
  .nav__open__show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #33333394;
    z-index: 10000;
    -webkit-transition: 0.4s linear;
    transition: 0.4s linear;
  }
  .logo a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .burger__btn {
    display: block;
  }
  .burger__open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: none;
    outline: none;
    padding: 10px;
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    -webkit-transition: 0.6s linear;
    transition: 0.6s linear;
  }
  .burger__open span {
    display: block;
    height: 3px;
    width: 30px;
    background: #676cff;
    margin: 3px 0;
    -webkit-transition: 0.6s linear;
    transition: 0.6s linear;
  }
  .burger__open:active span {
    -webkit-box-shadow: inset 2px 2px 6px rgba(154, 147, 140, 0.5), 1px 1px 8px white;
            box-shadow: inset 2px 2px 6px rgba(154, 147, 140, 0.5), 1px 1px 8px white;
  }
  .burger__close {
    top: 15px;
    right: 20px;
  }
  .burger__close span {
    border-radius: 11px;
    margin: 0;
  }
  .burger__close span:nth-child(1) {
    -webkit-transform: translateY(3px) rotate(-45deg);
            transform: translateY(3px) rotate(-45deg);
  }
  .burger__close span:nth-child(2) {
    opacity: 0;
  }
  .burger__close span:nth-child(3) {
    -webkit-transform: translateY(-2px) rotate(45deg);
            transform: translateY(-2px) rotate(45deg);
  }
  .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    position: fixed;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    top: 0;
    right: -100%;
    background: #4b4b4bde;
    -webkit-backdrop-filter: blur(120px) !important;
            backdrop-filter: blur(120px) !important;
    padding: 30px 80px;
    height: 100vh;
    -webkit-transition: 0.5s linear;
    transition: 0.5s linear;
  }
  .nav nav, .nav div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .nav nav a {
    margin: 10px 0;
  }
  .nav__open {
    right: 0;
  }
  .navbar {
    height: 100px;
  }
  .sing__in__btn, .sing__up__btn {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .burger__btn {
    position: absolute;
    right: 0;
    top: 0;
  }
  .nav__fixed {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .my__atropos2 .atropos-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 600px) {
  .logo img {
    width: 80px;
    height: 80px;
  }
  .burger__open {
    top: 10px;
    right: 10px;
  }
}

@media (max-width: 375px) {
  .nav__fixed {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
