.playlist__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 30px;
  background-image: url("../../images/home/homeOqituvchi/UK_flag.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0;
}

.playlist__container video {
  cursor: pointer;
}

.playlist__container .playlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 10px;
  margin-top: 20px;
}

.playlist__container .react-player__preview {
  background-size: cover !important;
  background-repeat: no-repeat;
}

.player-wrapper {
  max-height: 700px;
  height: auto !important;
}
