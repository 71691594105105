.bgwhite {
  background: radial-gradient(50% 50% at 50% 50%, #0010A0 0%, #000852 100%);
  width: 100%;
}

.abiturient__container {
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  height: 1000px;
  position: relative;
  padding: 0 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.abiturient__container h1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 88px;
  color: #FFFFFF;
  margin-bottom: 40px;
  z-index: 10;
}

.abiturient__container h3 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #FFFFFF;
  margin-bottom: 60px;
  z-index: 10;
}

.link__animated {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  color: #FFFFFF;
  width: 340px;
  height: 90px;
  background: #363CC7;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  z-index: 10;
  border: none;
  cursor: pointer;
}

.link__animated:before {
  content: "";
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #34f5e2, #4a4a4a, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  -webkit-animation: glowing 20s linear infinite;
          animation: glowing 20s linear infinite;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.link__animated:active {
  color: #00fff7;
}

.link__animated:active:after {
  background: transparent;
}

.link__animated:hover:before {
  opacity: 1;
}

.link__animated:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #363CC7;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@-webkit-keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.ab__img {
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
}

.ab__animation1 {
  position: absolute;
  bottom: 50px;
  -webkit-animation: animate 7s linear infinite;
          animation: animate 7s linear infinite;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

.ab__animation2 {
  position: absolute;
  top: 0px;
  right: 90px;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  -webkit-animation: animate1 6s linear infinite;
          animation: animate1 6s linear infinite;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

.ab__animation3 {
  position: absolute;
  bottom: 50px;
  right: 50px;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

.maktab_qurollari1 {
  -webkit-animation: animate11 7s linear infinite;
          animation: animate11 7s linear infinite;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

.maktab_qurollari2 {
  -webkit-animation: animate1 6s linear infinite;
          animation: animate1 6s linear infinite;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

.maktab_qurollari3 {
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

@-webkit-keyframes animate {
  0%, 100% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  50% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
}

@keyframes animate {
  0%, 100% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  50% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
}

@-webkit-keyframes animate11 {
  0%, 100% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
}

@keyframes animate11 {
  0%, 100% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
}

@-webkit-keyframes animate1 {
  0%, 100% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

@keyframes animate1 {
  0%, 100% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

@media (max-width: 1670px) {
  .ab__img {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .abiturient__container {
    height: 850px;
  }
  .abiturient__container h1 {
    font-size: 60px;
  }
  .abiturient__container h3 {
    font-size: 20px;
  }
  .abiturient__container a {
    font-size: 20px;
    width: 300px;
    height: 70px;
  }
  .ab__animation1 {
    width: 160px;
  }
  .ab__animation2 {
    width: 200px;
  }
  .ab__animation3 {
    width: 100px;
  }
  .ab__img {
    height: 850px;
    width: 100%;
  }
}

@media (max-width: 1330px) {
  .ab__img {
    width: auto;
  }
}

@media (max-width: 1225px) {
  .abiturient__container {
    height: 700px;
  }
  .abiturient__container h1 {
    font-size: 50px;
    margin-bottom: 30px;
    line-height: 75px;
  }
  .abiturient__container a {
    font-size: 20px;
    width: 300px;
    height: 70px;
  }
  .ab__img {
    height: 750px;
    width: auto;
  }
}

@media (max-width: 1024px) {
  .abiturient__container {
    height: 600px;
    padding: 0 100px;
  }
  .abiturient__container h1 {
    font-size: 45px;
  }
  .abiturient__container a {
    width: 250px;
    height: 60px;
  }
  .ab__animation1 {
    left: 30px;
  }
  .ab__animation2 {
    right: 30px;
    top: 0;
    width: 150px;
  }
}

@media (max-width: 900px) {
  .abiturient__container {
    height: 550px;
    padding: 0 80px;
  }
  .abiturient__container h1 {
    font-size: 35px;
    line-height: 50px;
  }
  .abiturient__container a {
    width: 220px;
    height: 55px;
  }
  .ab__img {
    height: 550px;
  }
}

@media (max-width: 768px) {
  .ab__animation1 {
    width: 100px;
  }
  .ab__animation2 {
    width: 120px;
  }
  .ab__animation3 {
    width: 70px;
  }
}

@media (max-width: 690px) {
  .abiturient__container {
    padding: 0 20px;
  }
  .abiturient__container h1 {
    text-shadow: 0px 0px 6px black;
  }
  .ab__img {
    right: -50px;
  }
}

@media (max-width: 425px) {
  .abiturient__container h1 {
    font-size: 30px;
  }
  .abiturient__container a {
    font-size: 17px;
    width: 180px;
    height: 45px;
  }
  .ab__animation2 {
    right: 0;
  }
}
