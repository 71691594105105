.login__container {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  background: #1B013C url(../../assets/images/Login/bg_login.webp);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  align-items: center;
  justify-items: center;
}

.login__container h1 {
  position: absolute;
  top: 100px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 88px;
  text-align: center;
  color: #FFFFFF;
}

.login__container .login__box {
  width: 309px;
  height: 472px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 4px;
  background-position: center !important;
  background-size: cover !important;
}

.login__container .login__box p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 130px;
  max-width: 280px;
}

.login__container .login__box a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 31px;
  color: #FFFFFF;
  width: 215px;
  height: 60px;
  background: #363CC7;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 30px;
}

.login__container .login__box:nth-child(1) {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(174, 137, 6, 0)), color-stop(91.15%, rgba(220, 147, 5, 0.88))), url(../../assets/images/Login/maktab.png);
  background: linear-gradient(180deg, rgba(174, 137, 6, 0) 0%, rgba(220, 147, 5, 0.88) 91.15%), url(../../assets/images/Login/maktab.png);
}

.login__container .login__box:nth-child(2) {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 0, 82, 0)), color-stop(78.12%, rgba(50, 0, 114, 0.88))), url(../../assets/images/Login/abiturient.png);
  background: linear-gradient(180deg, rgba(36, 0, 82, 0) 0%, rgba(50, 0, 114, 0.88) 78.12%), url(../../assets/images/Login/abiturient.png);
}

.login__container .login__box:nth-child(3) {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 0, 82, 0)), color-stop(78.12%, rgba(114, 0, 68, 0.88))), url(../../assets/images/Login/chettili.png);
  background: linear-gradient(180deg, rgba(36, 0, 82, 0) 0%, rgba(114, 0, 68, 0.88) 78.12%), url(../../assets/images/Login/chettili.png);
}

.login__container .login__box:nth-child(4) {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(36.98%, rgba(0, 82, 38, 0)), color-stop(78.12%, rgba(0, 114, 11, 0.65))), url(../../assets/images/Login/oqituvchi.png);
  background: linear-gradient(180deg, rgba(0, 82, 38, 0) 36.98%, rgba(0, 114, 11, 0.65) 78.12%), url(../../assets/images/Login/oqituvchi.png);
}

.loginpage__container {
  margin: 0 auto;
}

.login__page {
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden scroll;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.login__page .login__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 100px;
}

.login__page .login__title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 60px;
  color: #FFFFFF;
  margin-bottom: 30px;
  text-align: center;
  max-width: 750px;
}

.login__page form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px 65px;
}

.login__page form h1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 57px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 60px;
}

.login__page form h2 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 75px;
  text-align: center;
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.login__page form p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF;
  margin-bottom: 20px;
  width: 440px;
}

.login__page form input {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 21px;
  color: #636363;
  margin-bottom: 75px;
  background: #FFFFFF;
  -webkit-box-shadow: inset 6px 6px 15px rgba(0, 0, 0, 0.1);
          box-shadow: inset 6px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 70px;
  outline: none;
  border: none;
  text-align: center;
  padding: 0 20px;
}

.login__page form input::-webkit-input-placeholder {
  text-align: center;
}

.login__page form input:-ms-input-placeholder {
  text-align: center;
}

.login__page form input::-ms-input-placeholder {
  text-align: center;
}

.login__page form input::placeholder {
  text-align: center;
}

.login__page form button {
  width: 100%;
  height: 70px;
  background: #363CC7;
  -webkit-box-shadow: 0px 6.50969px 16.2742px rgba(3, 58, 254, 0.25);
          box-shadow: 0px 6.50969px 16.2742px rgba(3, 58, 254, 0.25);
  border-radius: 10px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
}

.loginpage__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.loginpage__img img {
  max-height: 100vh;
  width: auto;
}

.abiturient {
  background: url(../../assets/images/Login/abiturient_bg.webp);
  background-position: center;
  background-size: cover;
}

.abiturient form {
  background: #6c00c16e;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.chettili {
  background: url(../../assets/images/Login/chettili_bg.webp);
  background-position: center;
  background-size: cover;
}

.chettili form {
  background: rgba(194, 194, 194, 0.3);
  border-radius: 10px;
}

.oqituvchi {
  background: url(../../assets/images/Login/oqituvchi__bg.webp);
  background-position: center;
  background-size: cover;
}

.oqituvchi form {
  background: url(../../assets/images/Login/oqituvchi__form.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.oqituvchi form p, .oqituvchi form button {
  width: 380px;
}

.oqituvchi form input {
  width: 340px;
}

@media (max-width: 1700px) {
  .login__page .login__title {
    font-size: 45px;
    line-height: 55px;
  }
}

@media (max-width: 1440px) {
  .login__page .login__title {
    font-size: 35px;
    z-index: 1;
    text-shadow: 0px 0px 10px black;
  }
  .login__page form {
    padding: 40px 70px;
  }
  .login__page form h1 {
    margin-bottom: 60px;
    text-shadow: 0px 0px 10px black;
  }
  .login__page form p {
    text-shadow: 0px 0px 10px black;
  }
  .login__page form input {
    font-size: 18px;
  }
  .login__container h1 {
    top: 60px;
    font-size: 60px;
  }
}

@media (max-width: 1300px) {
  .login__container .login__box {
    width: 250px;
    height: 400px;
  }
  .login__container .login__box p {
    font-size: 22px;
  }
  .login__container .login__box a {
    width: 180px;
    height: 50px;
  }
}

@media (max-width: 1024px) {
  .login__container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .login__container h1 {
    top: 30px;
    font-size: 50px;
  }
  .login__page {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-top: 50px;
  }
  .login__page .login__form {
    margin-left: 0;
  }
  .login__page form {
    padding: 30px 50px;
    z-index: 1;
  }
  .login__page form h1 {
    margin-bottom: 60px;
  }
  .login__page form p {
    margin-bottom: 15px;
  }
  .login__page form input {
    margin-bottom: 50px;
  }
  .loginpage__img {
    position: absolute;
    z-index: 0;
    bottom: 0;
  }
  .loginpage__img img {
    z-index: 0;
  }
  .abiturient form, .chettili form {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .oqituvchi .loginpage__img {
    display: none;
  }
}

@media (max-width: 996px) {
  .login__container {
    padding-top: 150px;
    grid-gap: 50px;
  }
}

@media (max-height: 770px) {
  .login__form .login__title {
    line-height: 40px;
    font-size: 30px;
    max-width: 525px;
  }
  .login__form form p {
    width: 380px;
    font-size: 20px;
  }
  .login__form form input {
    margin-bottom: 35px;
    height: 55px;
  }
  .login__form form button {
    height: 55px;
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .login__container h1 {
    font-size: 40px;
  }
  .login__page {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .login__page form {
    margin-left: 0;
    margin-bottom: 50px;
  }
  .oqituvchi form {
    margin-top: 100px;
  }
}

@media (max-height: 600px) {
  .login__page {
    padding-top: 30px;
  }
  .login__form .login__title {
    font-size: 27px;
    line-height: 30px;
  }
  .login__form form {
    margin-bottom: 30px;
  }
  .login__form form h1 {
    margin-bottom: 50px;
  }
}

@media (max-width: 525px) {
  .login__page .login__form {
    width: 100%;
  }
  .login__page .login__title {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .login__container {
    grid-gap: 20px;
    padding: 150px 0 50px 0;
  }
  .login__container h1 {
    line-height: 45px;
    font-size: 30px;
  }
  .login__container .login__box {
    width: 90%;
    height: 250px;
    grid-gap: 20px;
    padding: 20px;
  }
  .login__container .login__box p {
    margin-top: 0;
    max-width: none;
    text-shadow: 0px 0px 5px black;
  }
  .login__container .login__box a {
    margin-bottom: 0;
  }
}

@media (max-width: 450px) {
  .login__page form {
    padding: 30px 20px;
    width: 90%;
  }
  .login__page form input {
    height: 55px;
    width: 90%;
    padding: 0 10px;
  }
  .login__page form h2 {
    line-height: 33px;
    font-size: 25px;
  }
  .login__page form p, .login__page form button {
    height: 55px;
    margin-bottom: 0;
    width: 90%;
  }
  .chettili form {
    background: none;
  }
  .chettili::after {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(194, 194, 194, 0.3);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    display: block;
  }
}

@media (max-width: 425px) {
  .login__page .login__title {
    font-size: 25px;
    line-height: 30px;
  }
  .oqituvchi form {
    width: 98%;
  }
}

@media (max-width: 375px) {
  .login__page .login__form {
    width: 100%;
  }
  .login__page .login__title {
    font-size: 22px;
  }
  .login__page form {
    padding: 0;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .login__page form input {
    height: 55px;
    padding: 0 10px;
  }
  .login__page form p, .login__page form button {
    height: 55px;
    margin-bottom: 0;
  }
  .abiturient::after {
    content: "";
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #6d00c13b;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    display: block;
  }
  .abiturient form {
    background: none;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
  }
  .oqituvchi form {
    width: 97% !important;
    padding: 20px;
  }
  .oqituvchi form input {
    margin-bottom: 30px;
  }
}

@media (max-width: 360px) {
  .oqituvchi form {
    min-height: 550px;
  }
  .oqituvchi form h1 {
    margin-bottom: 30px;
  }
  .oqituvchi form h2 {
    font-size: 20px;
  }
}

@media (max-width: 345px) {
  .login__page .login__title {
    margin-bottom: 0;
  }
  .login__page form {
    min-height: 500px;
  }
  .login__page form input {
    margin-bottom: 30px;
    height: 50px;
    font-size: 16px;
  }
  .login__page form button, .login__page form p {
    height: 50px;
    font-size: 18px;
  }
  .login__page form h1 {
    margin-bottom: 35px;
    font-size: 35px;
  }
}
