@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
.oqituvchi1 {
  background-image: url("../../images/home/homeOqituvchi/UK_flag.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.oqituvchi__inner1 {
  width: 97%;
  max-width: 1600px;
  min-height: 1000px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lastwordfont {
  font-size: 28px !important;
  line-height: 35px !important;
}

.oqituvchi__left {
  position: relative;
  width: 35%;
}

.oqituvchi__left img {
  height: 100%;
}

.oqituvchi__left .oqituvchi__left-circle {
  position: absolute;
  top: 200px;
  right: -280px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url("../../images/home/homeOqituvchi/circle1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  font-weight: 700;
  font-size: 53.7859px;
  line-height: 65px;
  width: 220px;
  height: 220px;
  -webkit-animation: circle1 2s infinite;
          animation: circle1 2s infinite;
}

.oqituvchi__left .oqituvchi__left-circle span {
  color: #B64348;
}

@-webkit-keyframes circle1 {
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@keyframes circle1 {
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

.oqituvchi__right {
  position: relative;
  width: 35%;
}

.oqituvchi__right-title {
  font-weight: 600;
  font-size: 53px;
  line-height: 85px;
  color: #FFFFFF;
}

.oqituvchi__right p {
  font-size: 35px;
  line-height: 52px;
  color: #FFFFFF;
}

.oqituvchi__right-btn {
  background: #363CC7;
  border-radius: 12.1687px;
  padding: 30px 110px;
  color: white;
  border: none;
  margin-top: 8px;
  cursor: pointer;
}

.oqituvchi__right .oqituvchi__right-circle {
  position: absolute;
  left: -280px;
  top: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url("../../images/home/homeOqituvchi/circle2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  font-weight: 700;
  font-size: 53.7859px;
  line-height: 65px;
  width: 300px;
  height: 330px;
  -webkit-animation: circle2 5s infinite;
          animation: circle2 5s infinite;
}

.oqituvchi__right .oqituvchi__right-circle span {
  color: #B64348;
}

@-webkit-keyframes circle2 {
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

@keyframes circle2 {
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

@media (max-width: 900px) {
  .oqituvchi__inner1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .oqituvchi__inner1 .oqituvchi__right {
    width: 100%;
  }
  .oqituvchi__inner1 .oqituvchi__right .oqituvchi__right-circle {
    position: absolute;
    top: 700px;
    left: 600px;
  }
  .oqituvchi__left {
    width: 100%;
  }
  .oqituvchi__left .oqituvchi__left-circle {
    bottom: 0;
    right: 20%;
  }
}

.oqituvchi__icon-list {
  position: absolute;
  right: 0;
  top: 55%;
  list-style-type: none;
  background: #0066AB;
  padding: 25px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.oqituvchi__icon-list li {
  margin: 15px 0;
}

.oqituvchi__icon-list a {
  color: white;
  font-size: 24px;
}

@media (max-width: 1440px) {
  .oqituvchi__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .oqituvchi__right {
    width: 45%;
  }
}

@media (max-width: 1300px) {
  .oqituvchi__left .oqituvchi__left-circle {
    width: 150px;
    height: 150px;
    font-size: 30px;
    line-height: 20px;
  }
  .oqituvchi__right-title {
    font-size: 50px;
    line-height: 85px;
  }
  .oqituvchi__right p {
    font-size: 22px;
    line-height: 32px;
  }
  .oqituvchi__right .oqituvchi__right-circle {
    width: 190px;
    height: 210px;
    font-size: 30px;
    line-height: 20px;
    left: -80px;
  }
  .oqituvchi__icon-list {
    padding: 40px 18px;
  }
}

@media (max-width: 1200px) {
  .oqituvchi__right-title {
    font-size: 45px;
    line-height: 70px;
  }
  .oqituvchi__right a {
    width: 250px;
    height: 60px;
  }
}

@media (max-width: 900px) {
  .oqituvchi__inner1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .oqituvchi__inner1 .oqituvchi__right {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .oqituvchi__inner1 .oqituvchi__right-title {
    text-align: center;
    font-size: 40px;
  }
  .oqituvchi__inner1 .oqituvchi__right-btn {
    margin: 0 auto;
  }
}

.oqituvchi2 {
  height: 100vh;
  background-image: url("../../images/home/homeOqituvchi/bg_o.webp");
  background-size: cover;
  position: relative;
  z-index: 101;
}

.oqituvchi__inner2 {
  height: 100%;
  width: 97%;
  max-width: 1600px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}

.oqituvchi__inner2 .oqituvchi2__info {
  width: 45%;
}

.oqituvchi__inner2 .oqituvchi2__info h2 {
  font-weight: 600;
  font-size: 50px;
  line-height: 105px;
  text-align: start;
  color: #FFFFFF;
  border-bottom: 1px solid #fff;
}

.oqituvchi__inner2 .oqituvchi2__info p {
  margin-top: 66px;
  font-size: 32px;
  line-height: 52px;
  text-align: start;
  color: #FFFFFF;
  border-bottom: 1px solid #fff;
}

.oqituvchi__inner2 .oqituvchi2__info a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #036B06;
  width: 340px;
  height: 90px;
  background: #FFFFFF;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 100px;
}

.oqituvchi__inner2 .oqituvchi2__img {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 1100px) {
  .oqituvchi2 {
    height: 700px;
  }
  .oqituvchi__inner2 .oqituvchi2__info {
    z-index: 10;
  }
  .oqituvchi__inner2 .oqituvchi2__info h2 {
    font-weight: 400;
    font-size: 25px;
    line-height: 55px;
  }
  .oqituvchi__inner2 .oqituvchi2__info a {
    z-index: 10;
  }
  .oqituvchi__inner2 .oqituvchi2__img {
    width: 600px;
    z-index: 1;
  }
}

@media (max-width: 770px) {
  .oqituvchi2 {
    height: 800px;
  }
  .oqituvchi1, .oqituvchi__inner1 {
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
  }
  .oqituvchi__inner2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .oqituvchi__inner2 .oqituvchi2__info {
    text-align: center;
  }
  .oqituvchi__left img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 650px) {
  .oqituvchi__right-circle,
  .oqituvchi__left-circle {
    display: none !important;
  }
  .oqituvchi2 {
    height: 950px;
  }
}

@media (max-width: 425px) {
  .oqituvchi__inner2 .oqituvchi2__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .oqituvchi__inner2 .oqituvchi2__info a {
    width: 300px;
    height: 60px;
    margin-top: 50px;
  }
  .oqituvchi__inner1 .oqituvchi__right-title {
    font-size: 30px;
    line-height: 45px;
    margin-top: 20px;
  }
  .oqituvchi__inner1 .oqituvchi__right p {
    font-size: 20px;
  }
  .oqituvchi__inner1 .oqituvchi__right a {
    width: 170px;
    height: 45px;
    font-size: 17px;
  }
}

@media (max-width: 320px) {
  .oqituvchi__inner1 .oqituvchi__right-title {
    font-size: 27px;
    line-height: 35px;
  }
  .oqituvchi__inner1 .oqituvchi__right p {
    font-size: 18px;
  }
}
