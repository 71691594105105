.p__maktab-wrapper {
  width: 100%;
  padding-bottom: 1px;
  background-image: url("../../assets/images/Login/Prezident_maktab_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.p__maktabi {
  padding-bottom: 200px;
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/Login/p_m_bulut.png"), url("../../assets/images/Login/p_m_sayyora.png"), url("../../assets/images/Login/p_m_roceta.png");
  background-repeat: no-repeat;
  background-position: top -50px left -50px, top -200px right -200px, bottom -400px right -200px;
}

.p__maktabi-inner {
  -webkit-transform: translateX(15vw);
          transform: translateX(15vw);
  max-width: 797px;
  margin: 0 auto;
}

.p__maktabi-inner h2 {
  max-width: 555px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 40px;
  line-height: normal;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0 0 5px #000;
}

.p__maktabi-inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 57px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0 0 5px #000;
}

.p__maktabi-form {
  margin: 0 auto;
  max-width: 455px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.p__maktabi-form label {
  color: white;
  margin-top: 36px;
  margin-bottom: 10px;
  font-size: 20px;
}

.p__maktabi-form input {
  text-align: center;
  background: #FFFFFF;
  -webkit-box-shadow: inset 6px 6px 15px rgba(0, 0, 0, 0.1);
          box-shadow: inset 6px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 32px;
}

.p__maktabi-form button {
  margin-top: 65px;
  padding: 25px;
  background: #363CC7;
  -webkit-box-shadow: 0px 6.50969px 16.2742px rgba(3, 58, 254, 0.25);
          box-shadow: 0px 6.50969px 16.2742px rgba(3, 58, 254, 0.25);
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 27px;
  line-height: 35px;
}

@media (max-width: 900px) {
  .p__maktabi-inner {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .p__maktabi-inner h2 {
    font-size: 50px;
    line-height: 38px;
    max-width: 597px;
    margin: 0 auto;
  }
  .p__maktabi-inner p {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 57px;
  }
  .p__maktabi-form {
    padding: 0 20px;
  }
}

@media (max-width: 700px) {
  .p__maktabi {
    height: auto;
    background-size: 300px, 300px, 400px;
    background-position: top -30px left -30px, top -150px right -150px, bottom -100px right -50px;
  }
}

@media (max-width: 520px) {
  .p__maktabi-inner h2 {
    font-size: 30px;
  }
  .p__maktabi-form label {
    margin-top: 26px;
    margin-bottom: 5px;
  }
  .p__maktabi-form input {
    padding: 18px;
  }
  .p__maktabi-form button {
    margin-top: 45px;
    padding: 15px;
  }
}
