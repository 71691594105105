.reyting {
  background-image: url("../../images/home/homeOqituvchi/reyting_bg.webp");
  padding-top: 76px;
  padding-bottom: 95px;
  z-index: 102;
  position: relative;
}

.affix_scroll .ant-affix {
  position: absolute !important;
  overflow-y: scroll !important;
}

.affix_scroll .ant-affix::-webkit-scrollbar {
  width: 0em;
}

.affix_scroll .ant-affix::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.affix_scroll .ant-affix::-webkit-scrollbar-thumb {
  background-color: #0042d1;
  border-radius: 2px;
}

.reyting__title {
  width: 448px;
  padding: 17px;
  background: #FFFFFF;
  -webkit-box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
          box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 70px;
  line-height: 88px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 500px) {
  .reyting__title {
    max-width: 300px;
    font-size: 50px;
    line-height: 40px;
  }
}

.reyting__list {
  width: 97%;
  max-width: 1600px;
  margin: 0 auto;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.reyting__list-item {
  text-align: center;
  margin-top: 75px;
  width: 370px;
  height: 485px;
  border: 17px solid #4F290C;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 10px #000;
          box-shadow: inset 0 0 10px #000;
  position: relative;
}

.reyting__list-item .reyting__statistic {
  position: absolute;
  top: 60px;
  left: 30px;
  border: 3px solid #FFC850;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fd0000;
  font-size: 16px;
}

.reyting__list-item .reyting__star {
  height: 40px;
}

.reyting__list-item .reyting__baner {
  width: 290px;
  height: 340px;
  margin: 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 800px) {
  .reyting__list {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
