.ariza__modal {
  width: 960px !important;
}

.ariza__modal .ant-modal-content {
  padding: 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(194, 194, 194, 0.3);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-radius: 10px;
}

.ariza__modal .ant-modal-header {
  border-bottom: none;
  background: none;
}

.ariza__modal .ant-modal-body {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.ariza__modal .ant-modal-body p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.ariza__modal .ant-modal-body input, .ariza__modal .ant-modal-body textarea {
  background: #FFFFFF;
  -webkit-box-shadow: inset 6px 6px 15px rgba(0, 0, 0, 0.1);
          box-shadow: inset 6px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 400px;
  border: none;
  outline: none;
  padding-left: 10px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.ariza__modal .ant-modal-body input::-webkit-input-placeholder, .ariza__modal .ant-modal-body textarea::-webkit-input-placeholder {
  text-align: center;
  font-size: 13px;
}

.ariza__modal .ant-modal-body input:-ms-input-placeholder, .ariza__modal .ant-modal-body textarea:-ms-input-placeholder {
  text-align: center;
  font-size: 13px;
}

.ariza__modal .ant-modal-body input::-ms-input-placeholder, .ariza__modal .ant-modal-body textarea::-ms-input-placeholder {
  text-align: center;
  font-size: 13px;
}

.ariza__modal .ant-modal-body input::placeholder, .ariza__modal .ant-modal-body textarea::placeholder {
  text-align: center;
  font-size: 13px;
}

.ariza__modal .ant-modal-body input {
  height: 60px;
}

.ariza__modal .ant-modal-body textarea {
  height: 200px;
  max-height: 200px;
  min-height: 200px;
}

.ariza__modal .ant-modal-footer {
  border-top: none;
}

.ariza__modal .ant-modal-title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 45.4799px;
  line-height: 57px;
  text-align: center;
  color: #FFFFFF;
}

.ariza__modal .ant-btn-default {
  display: none;
}

.ariza__modal .ant-btn-primary {
  background: #363CC7;
  border: none;
  -webkit-box-shadow: 0px 6px 16px rgba(3, 58, 254, 0.25);
          box-shadow: 0px 6px 16px rgba(3, 58, 254, 0.25);
  border-radius: 10px;
  width: 400px;
  height: 60px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
}

.ariza__bgbot {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 0;
  pointer-events: none;
}

.ariza__teacher {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  height: 100vh;
  min-height: 900px;
  background: url(../../assets/images/Kabinet/oqituvchi_headerbg.webp);
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.ariza__teacher img {
  pointer-events: none;
}

.ariza__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 900px;
  z-index: 1;
  position: relative;
}

.ariza__content img {
  position: absolute;
  z-index: -1;
  -webkit-transform: rotate(0.7deg);
          transform: rotate(0.7deg);
}

.ariza__content h1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 70px;
  color: #000000;
  margin-bottom: 50px;
  text-align: center;
}

.ariza__content p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.ariza__content button {
  background: #363CC7;
  -webkit-box-shadow: 0px 15px 35px rgba(3, 13, 255, 0.2);
          box-shadow: 0px 15px 35px rgba(3, 13, 255, 0.2);
  border-radius: 5px;
  width: 237px;
  height: 62px;
  font-size: 19px;
}

.ariza__content button:hover {
  background: #363CC7;
  -webkit-box-shadow: 0px 15px 20px rgba(3, 13, 255, 0.2);
          box-shadow: 0px 15px 20px rgba(3, 13, 255, 0.2);
}

@media (max-width: 1440px) {
  .ariza__content {
    width: 800px;
  }
  .ariza__content img {
    width: 150%;
  }
  .ariza__content h1 {
    font-size: 40px;
  }
  .ariza__content p {
    font-size: 28px;
    line-height: 40px;
  }
  .ariza__tleft img {
    width: 100%;
  }
}

@media (max-width: 1170px) {
  .ariza__teacher {
    height: 800px;
    min-height: auto;
  }
  .ariza__tleft {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
  }
  .ariza__content img {
    left: -20%;
  }
  .ariza__content h1 {
    font-size: 36px;
    margin-bottom: 25px;
  }
  .ariza__content p {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .ariza__bgbot {
    width: 130%;
  }
}

@media (max-width: 1024px) {
  .ariza__teacher {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .ariza__content {
    height: 450px;
  }
  .ariza__content img {
    width: 130%;
    left: -15%;
    top: -25%;
  }
}

@media (max-width: 910px) {
  .ariza__modal .ant-modal-body input, .ariza__modal .ant-modal-body textarea {
    width: 320px;
  }
  .ariza__modal .ant-btn-primary {
    width: 320px;
  }
}

@media (max-width: 800px) {
  .ariza__content {
    width: 100%;
    height: 400px;
  }
  .ariza__content img {
    top: -50%;
  }
}

@media (max-width: 768px) {
  .ariza__modal .ant-modal-body {
    grid-gap: 50px 25px;
    padding: 20px 0;
    width: 96%;
  }
  .ariza__modal .ant-modal-body input, .ariza__modal .ant-modal-body textarea {
    width: 100%;
  }
  .ariza__modal .ant-btn-primary {
    width: 320px;
  }
}

@media (max-width: 710px) {
  .ariza__content img {
    top: -30%;
    width: 124%;
    left: -13%;
  }
}

@media (max-width: 610px) {
  .ariza__content img {
    top: -20%;
    width: 124%;
    left: -13%;
  }
}

@media (max-width: 560px) {
  .ariza__modal {
    top: 10px;
  }
  .ariza__modal .ant-modal-body {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    grid-gap: 25px;
  }
  .ariza__modal .ant-modal-body p {
    font-size: 20px;
  }
  .ariza__modal .ant-modal-title {
    font-size: 35px;
  }
  .ariza__modal .ant-modal-content {
    padding: 30px 0;
  }
  .ariza__content {
    background: #e5e5e591;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    height: auto;
    padding: 40px 10px;
  }
  .ariza__content img {
    display: none;
  }
  .ariza__tleft {
    height: 400px;
  }
  .ariza__tleft img {
    height: 100%;
    width: auto;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}

@media (max-width: 425px) {
  .ariza__content h1 {
    font-size: 35px;
    line-height: 33px;
  }
  .ariza__content p {
    font-size: 24px;
    line-height: 29px;
  }
  .ariza__tleft img {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  .ariza__modal .ant-btn-primary {
    width: 100%;
    margin-left: 0 !important;
    font-size: 22px;
  }
  .ariza__modal .ant-modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 96%;
    padding: 0;
  }
}

@media (max-width: 375px) {
  .ariza__content h1 {
    font-size: 32px;
    line-height: 30px;
  }
  .ariza__content p {
    font-size: 22px;
    line-height: 26px;
  }
  .ariza__tleft img {
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }
}

@media (max-width: 320px) {
  .ariza__content h1 {
    font-size: 28px;
    line-height: 28px;
  }
  .ariza__content p {
    font-size: 18px;
    line-height: 22px;
  }
  .ariza__tleft img {
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }
}
