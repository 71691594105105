@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
.taxrirlash {
  padding-top: 250px;
  max-width: 1130px;
  margin: 0 auto;
}

.taxrirlash__form {
  margin-top: 10px;
  border-radius: 5px;
  background: #F4F4F4;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  padding: 30px 50px 90px 50px;
}

.taxrirlash__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.taxrirlash__avatar-img {
  border-radius: 5px;
  width: 137px;
  height: 137px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.taxrirlash__avatar-img img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}

.taxrirlash__avatar-file {
  max-width: 844px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-width: 2px;
  border-style: dashed;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0 15px;
}

.taxrirlash__avatar-file input {
  display: none;
}

.taxrirlash__avatar-file label {
  border-radius: 5.26806px;
  padding: 10px 33px;
  color: white;
}

.taxrirlash__avatar-file label:hover {
  cursor: pointer;
}

@media (max-width: 450px) {
  .taxrirlash__form-title {
    font-size: 20px;
    text-align: center;
  }
  .taxrirlash__avatar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .taxrirlash__avatar div {
    margin: 10px auto;
  }
  .taxrirlash__avatar-file {
    border: none;
    margin-top: 15px;
  }
}

.taxrirlash__inputs-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 40%;
  min-width: 330px;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner div {
  width: 100%;
  margin-top: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner div label {
  font-size: 18px;
  line-height: 22px;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner div input {
  text-align: center;
  padding: 13px;
  outline: none;
  border: none;
  background-color: #fff;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner div select {
  padding: 13px;
  outline: none;
  border: none;
  background-color: #fff;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner div select:hover {
  cursor: pointer;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner .taxrirlash__btns {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner .taxrirlash__btns button {
  padding: 16px 48px;
  border-radius: 5.93771px;
  border: 1px solid;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner .taxrirlash__btns button:hover {
  cursor: pointer;
}

.taxrirlash__inputs-list .taxrirlash__inputs-inner .taxrirlash__btns .taxrirlash__btns-save {
  border: none;
  color: white;
  -webkit-box-shadow: 0px 5.93771px 11.8754px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 5.93771px 11.8754px rgba(93, 95, 239, 0.2);
}

.taxrirlash__btns-bekor1 {
  color: #D75706;
  border-color: #D75706;
}

.taxrirlash__btns-bekor2 {
  color: #0BA7BF;
  border-color: #0BA7BF;
}

.taxrirlash__btns-bekor3 {
  color: #0BA7BF;
  border-color: #0BA7BF;
}

.taxrirlash__btns-bekor4 {
  color: #1D794B;
  border-color: #1D794B;
}

.username_input {
  position: relative;
}

.username_input .username_loading {
  position: absolute;
  top: 30px;
  right: 35px;
  font-weight: bold;
  font-size: 20px;
}
