@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
.card_maktab1 {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706));
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%);
}

.card_maktab2 {
  background: #0BA7BF;
}

.card_maktab3 {
  background: #0BA7BF;
}

.card_maktab4 {
  background: #1D794B;
}

.card {
  padding-bottom: 10px;
  width: 294px;
  height: 390px;
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 46px;
  -webkit-box-shadow: 0px 4.54487px 9.08975px rgba(9, 9, 22, 0.2);
          box-shadow: 0px 4.54487px 9.08975px rgba(9, 9, 22, 0.2);
  position: relative;
}

.card .card__img {
  width: 294px;
  height: 183px;
  -o-object-fit: cover;
     object-fit: cover;
}

.card .card__top {
  position: absolute;
  background: #FF7A00;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50px;
  height: 22px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  top: 10px;
  left: 10px;
  -webkit-box-shadow: 0px 0px 8px -1px wheat;
          box-shadow: 0px 0px 8px -1px wheat;
}

.card .card__inner {
  position: relative;
  padding: 0 24px;
  padding-bottom: 11px;
}

.card .card__inner .card__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.card .card__inner .card__avatar img {
  margin-top: -20px;
  width: 68px;
  height: 68px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.card .card__inner .card__name {
  margin-top: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.card .card__inner .card__name .card__name-btn {
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 5px;
  font-size: 23px;
  background-color: transparent;
  border: none;
  color: #000;
  margin: 0;
}

.card .card__inner .card__name .card__name-btn:hover {
  cursor: pointer;
}

.card .card__inner .card__name .card__name-btn:active {
  color: #D75706;
}

.card .card__inner span {
  display: block;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.568109px;
  color: #212121;
  margin-top: 16px;
  margin-bottom: 8px;
}

.card .card__inner button {
  -webkit-box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
  border-radius: 5px;
  border: none;
  padding: 10px 85px;
  color: white;
  font-size: 17px;
  margin: 0 auto;
}

.card .card__inner button:hover {
  cursor: pointer;
}

.kurslar__wrapper {
  padding: 0 100px;
  padding-top: 120px;
}

.kurslar {
  max-width: 1240px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.kurslar__bar {
  width: 247px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.kurslar__bar p {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  border-bottom: 1.5px solid #000;
  margin-top: 25px;
  padding-bottom: 7px;
}

.kurslar__bar-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

.kurslar__bar-list {
  list-style-type: none;
}

.kurslar__bar-list li {
  padding: 5px 20px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 5px;
}

.k_i_h {
  color: white;
}

.kurslar__main {
  padding-left: 26px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.kurslar__main .category__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 20px;
}

.kurslar__main .category__title h2 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 0;
}

.kurslar__main .category__title button {
  display: none;
}

.kurslar__main-konikmalar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-bottom: 2px solid #BCBCBC;
  padding-bottom: 32px;
}

.kurslar__main-konikmalar p {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

.kurslar__main-konikmalar button {
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  padding: 9px 15px;
  font-size: 20px;
  line-height: 25px;
  margin-left: 15px;
  margin-top: 20px;
}

.kurslar__main-konikmalar button:hover {
  cursor: pointer;
}

.kurslar__main-konikmalar button:active {
  -webkit-box-shadow: inset 0 0 5px #000;
          box-shadow: inset 0 0 5px #000;
}

.kurslar__pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 50px;
}

.kurslar__pagination .ant-pagination-options {
  display: none;
}

.kurslar__pagination .ant-pagination {
  background: #ffffff;
  -webkit-box-shadow: 0px 4.5px 11px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 4.5px 11px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.kurslar__pagination .ant-pagination .ant-pagination-prev {
  margin-right: 50px;
}

.kurslar__pagination .ant-pagination .ant-pagination-next {
  margin-left: 50px;
}

.kurslar__pagination .ant-pagination .ant-pagination-item-active {
  background: rgba(241, 113, 32, 0.3);
  border-radius: 50%;
}

.kurslar__pagination .ant-pagination .ant-pagination-item {
  margin-right: 4px;
  margin-left: 4px;
}

.kurslar__pagination .ant-pagination li {
  border: none;
  background: none;
}

.kurslar__pagination .ant-pagination button {
  border: none;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.kurslar__pagination .ant-pagination button svg {
  width: 20px;
  height: 20px;
}

.kurslar__pagination .ant-pagination button path {
  fill: #F17120;
}

.kurslar__pagination .ant-pagination a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 20.5116px;
  line-height: 26px;
  color: #000000;
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.kurslar__filter {
  display: none;
}

.kurslar__main-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.kurslar__main-search select {
  margin: 30px;
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  padding: 9px 15px;
  outline: none;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
}

.kurslar__main-search select:hover {
  cursor: pointer;
}

.kurslar__main-search .kurslar__main__search {
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
}

.kurslar__main-search .kurslar__main__search button {
  height: 45px;
  width: 45px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: #000;
}

.kurslar__main-search input {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  padding: 0 15px;
  border-radius: 5px;
}

.kurslar__card-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-height: 500px;
}

@media (max-width: 1250px) {
  .kurslar__wrapper {
    padding: 120px 60px 0 60px;
  }
  .kurslar__main-search select {
    margin: 15px 5px;
  }
  .kurslar__main-search .kurslar__main__search {
    width: 380px;
  }
}

@media (max-width: 1024px) {
  .kurslar__wrapper {
    padding: 120px 30px 0 30px;
  }
}

@media (max-width: 960px) {
  .kurslar__wrapper {
    padding: 0px;
    padding-top: 120px;
  }
  .kurslar__main {
    padding: 0 25px;
  }
  .kurslar {
    position: relative;
  }
  .kurslar .kurslar__bar {
    position: fixed;
    top: 0;
    left: -300px;
    padding-top: 100px;
    background-color: #fff;
    height: 100%;
    z-index: 5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .kurslar .kurslar__bar .kurslar__filter {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .kurslar .kurslar__bar-block {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    left: 0px;
  }
  .kurslar__filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 45px;
    height: 45px;
    background: none;
    outline: none;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
  }
  .kurslar__main-search .kurslar__main__search {
    width: auto;
  }
}

@media (max-width: 767px) {
  .kurslar__main {
    padding: 0 15px;
  }
  .kurslar__main-search {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .kurslar__main-search .kurslar__filter {
    margin-right: 5px;
  }
  .kurslar__main-search .kurslar__main__search {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .kurslar__pagination .ant-pagination .ant-pagination-next {
    margin-left: 10px;
  }
  .kurslar__pagination .ant-pagination .ant-pagination-prev {
    margin-right: 10px;
  }
}

@media (max-width: 425px) {
  .kurslar__pagination .ant-pagination {
    padding: 7px;
  }
  .kurslar__pagination .ant-pagination a {
    font-size: 16px;
  }
  .kurslar__pagination .ant-pagination .ant-pagination-item {
    min-width: 10px;
    margin-right: 2px;
    margin-left: 2px;
  }
  .kurslar__pagination .ant-pagination li {
    width: 25px;
    height: 25px;
  }
  .kurslar__pagination .ant-pagination li a {
    width: 25px;
    height: 25px;
  }
  .kurslar__pagination .ant-pagination .ant-pagination-jump-next,
  .kurslar__pagination .ant-pagination .ant-pagination-jump-prev,
  .kurslar__pagination .ant-pagination .ant-pagination-next,
  .kurslar__pagination .ant-pagination .ant-pagination-prev {
    min-width: 10px;
  }
  .kurslar__main-search {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .kurslar__main-search .kurslar__filter {
    margin-right: 5px;
  }
  .kurslar__main-search .kurslar__main__search {
    width: 100%;
  }
  .kurslar__main-search .kurslar__main__search input {
    height: 40px;
  }
  .kurslar__main-search .kurslar__main__search button {
    width: 40px;
    height: 40px;
  }
  .kurslar__main-search select {
    font-size: 16px;
  }
  .kurslar__main-search span {
    font-size: 20px !important;
  }
  .kurslar__filter {
    height: 40px;
    width: 40px;
  }
  .card {
    margin-top: 30px;
  }
}

@media (max-width: 375px) {
  .kurslar__main {
    padding: 0 10px;
  }
  .kurslar__main-search select {
    padding: 9px 5px;
  }
}
