.ariza_me {
  max-width: 800px !important;
  width: 100% !important;
}

.ariza_me .ant-modal-header {
  border-bottom: none;
  background: none;
}

.ariza_me .ant-modal-body p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 15px;
}

.ariza_me .ant-modal-body input {
  background: #FFFFFF;
  border: none;
  padding-left: 10px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.ariza_me .ant-modal-body input::-webkit-input-placeholder {
  font-size: 13px;
}

.ariza_me .ant-modal-body input:-ms-input-placeholder {
  font-size: 13px;
}

.ariza_me .ant-modal-body input::-ms-input-placeholder {
  font-size: 13px;
}

.ariza_me .ant-modal-body input::placeholder {
  font-size: 13px;
}

.ariza_me .ant-modal-footer {
  border-top: none;
}

.ariza_me .ant-modal-content {
  padding: 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(167, 164, 164, 0.5);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  border-radius: 20px;
}

.ariza_me .ant-modal-title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
}

.ariza_me .ant-btn-default {
  display: none;
}

.ariza_me .ant-btn-primary {
  background: #363CC7;
  border-radius: 10px;
  border: none;
  width: 100%;
  min-width: 250px;
  height: 60px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 35px;
  color: #FFFFFF;
  margin: 0 !important;
}

.ariza_me #error__path {
  -webkit-animation: err 6s linear infinite;
          animation: err 6s linear infinite;
}

@-webkit-keyframes err {
  0%,
  100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

@keyframes err {
  0%,
  100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
