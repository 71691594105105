@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
#kabinet1 {
  background: url(../../assets/images/Kabinet/bg_maktab.png);
}

#kabinet2 {
  background: url(../../assets/images/Kabinet/bg_oqituvchi.webp);
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

#kabinet4 {
  background: url(../../assets/images/Kabinet/bg_oqituvchi.webp);
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.kabinet__navbar__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #1B0046;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 300;
}

.search__result {
  display: none;
}

.kabinet__navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 100px;
  height: 95px;
  width: 100%;
  max-width: 1920px;
}

.kabinet__navbar .kabinet__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0.1;
      -ms-flex-positive: 0.1;
          flex-grow: 0.1;
}

.kabinet__navbar .kabinet__nav a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  text-shadow: 2.5px 2px 0px #ffffff00, -2.5px -2px 0px #ffffff00;
}

.kabinet__navbar .kabinet__nav a:hover {
  text-shadow: 0px 0px 1px #000, 0px 0px 1px #000;
}

.kabinet__navbar .kabinet__nav button {
  background: none;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
}

.kabinet__navbar .kabinet__nav button img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.kabinet__navbar .kabinet__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  padding: 5px 7px;
}

.kabinet__navbar .kabinet__search input {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  padding-left: 10px;
  caret-color: white;
  background: none;
  outline: none;
  border: none;
}

.kabinet__navbar .kabinet__search input::-webkit-input-placeholder {
  color: white;
  font-weight: 200;
}

.kabinet__navbar .kabinet__search input:-ms-input-placeholder {
  color: white;
  font-weight: 200;
}

.kabinet__navbar .kabinet__search input::-ms-input-placeholder {
  color: white;
  font-weight: 200;
}

.kabinet__navbar .kabinet__search input::placeholder {
  color: white;
  font-weight: 200;
}

.kabinet__navbar .kabinet__search span {
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.k__mobile .a1 {
  display: none;
}

.kabinet__header {
  min-height: 1000px;
  padding: 135px 35px 35px 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

.kabinet__header h1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 62px;
  color: #FFFFFF;
  margin-bottom: 30px;
  text-align: center;
  z-index: 3;
  width: 60%;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.822);
  margin-top: 40px;
}

.kabinet__header p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  max-width: 1100px;
  color: #FFFFFF;
  margin-bottom: 50px;
  width: 65%;
  z-index: 3;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.822);
}

.kabinet__header .header__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 30px;
  z-index: 3;
}

.kabinet__header .header__items .header__item {
  max-width: 270px;
  min-width: 200px;
  height: 400px;
  background: #FFFFFF;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.kabinet__header .header__items .header__item .header__item__img {
  height: 200px;
  width: 100%;
  overflow: hidden;
  max-width: 330px;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.kabinet__header .header__items .header__item .header__item__img img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.kabinet__header .header__items .header__item b {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.22078px;
  color: #000000;
  width: 100%;
  padding-left: 15px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.kabinet__header .header__items .header__item span {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 11px;
  color: #000000;
  padding: 0 15px;
  margin-bottom: 15px;
}

.kabinet__header .header__items .header__item a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  background: #363CC7;
  -webkit-box-shadow: 0px 4px 8px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 4px 8px rgba(93, 95, 239, 0.2);
  border-radius: 5px;
  width: calc(100% - 30px);
  height: 42px;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}

.kabinet__header .header__items .header__item a:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0.5)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5));
  height: 50px;
  width: 160px;
  position: absolute;
  top: -3px;
  left: -250px;
  -webkit-transform: skewX(-45deg);
          transform: skewX(-45deg);
}

.kabinet__header .header__items .header__item a:hover:before {
  left: 250px;
  -webkit-transition: .7s ease-in-out;
  transition: .7s ease-in-out;
}

.kabinet__main {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.kabinet__slider {
  max-width: 1440px;
  width: 100%;
  padding: 49px;
  z-index: 1;
}

.kabinet__slider .slider__title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: #000000;
  padding-bottom: 15px;
  margin-bottom: 50px;
  width: 100%;
  border-bottom: 2px solid #363CC7;
}

.kabinet__swiper {
  padding: 0 50px 60px 50px !important;
}

.kabinet__swiper .swiper-pagination-bullet {
  width: 50px;
  height: 7px;
  border-radius: 10px;
}

.kabinet__swiper .swiper-pagination-bullet-active-prev,
.kabinet__swiper .swiper-pagination-bullet-active-prev-prev,
.kabinet__swiper .swiper-pagination-bullet-active-next,
.kabinet__swiper .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
}

.kabinet__swiper .swiper-pagination-bullet-active {
  background: #363CC7 !important;
}

.kabinet__swiper .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.kabinet__swiper .swiper-button-next,
.kabinet__swiper .swiper-button-prev {
  width: 50px;
}

.kabinet__swiper .swiper-button-next::after,
.kabinet__swiper .swiper-button-prev::after {
  background: #363CC7;
  font-size: 16px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.kabinet__swiper .swiper-button-next {
  right: 0;
}

.kabinet__swiper .swiper-button-prev {
  left: 0;
}

.kabinet__swiper .swiper-button-disabled {
  opacity: 1 !important;
}

.kabinet__swiper .swiper-button-disabled::after {
  opacity: .66;
}

.swiper__box {
  background: #252836;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 300px;
  height: 400px;
  overflow: hidden;
}

.swiper__box .swiper__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  width: 100%;
}

.swiper__box .swiper__img img {
  width: 100%;
  min-height: 180px;
  height: 180px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.swiper__box .swiper__img span {
  position: absolute;
  top: 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11.3622px;
  line-height: 11px;
  letter-spacing: 0.568109px;
  color: #FFFFFF;
  width: 52px;
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.swiper__box .swiper__img .swiper__top {
  left: 15px;
  background: #FF7A00;
  border-radius: 7px;
}

.swiper__box .swiper__img .swiper__soni {
  right: 15px;
  background: #363CC7;
  border-radius: 7px;
}

.swiper__box .swiper__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  grid-gap: 20px;
}

.swiper__box .swiper__user p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.568109px;
  color: #B7B9D2;
}

.swiper__box .swiper__user img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.swiper__box .swiper__user div {
  -webkit-transform: translateY(-25px);
          transform: translateY(-25px);
  position: relative;
  border: 1px solid white;
  border-radius: 50%;
  padding: 5px;
}

.swiper__box .swiper__user div::before {
  content: "\2714";
  background: #08A0F7;
  color: white;
  border: 3px solid #252836;
  border-radius: 50%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0;
  right: 0;
  width: 22px;
  height: 22px;
  font-size: 12px;
}

.swiper__box .swiper__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 30px;
}

.swiper__box .swiper__info p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.340865px;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.swiper__box .swiper__info span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.568109px;
  color: #808191;
}

.swiper__box .swiper__info button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.swiper__box .swiper__btn {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.568109px;
  color: #FFFFFF;
  background: #363CC7;
  -webkit-box-shadow: 0px 4.5px 9px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 4.5px 9px rgba(93, 95, 239, 0.2);
  border-radius: 11.3622px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 230px;
  height: 45px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.swiper__box .swiper__btn:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0.5)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5));
  height: 50px;
  width: 160px;
  position: absolute;
  top: -3px;
  left: -250px;
  -webkit-transform: skewX(-45deg);
          transform: skewX(-45deg);
}

.swiper__box .swiper__btn:hover:before {
  left: 250px;
  -webkit-transition: .7s ease-in-out;
  transition: .7s ease-in-out;
}

.kabinet__tavsiya {
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  position: relative;
}

.tavsiya__content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 0 0 150px;
  height: 500px;
}

.tavsiya__content h1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  color: #FFFFFF;
  margin-bottom: 25px;
  text-shadow: 0px 0px 8px #616161;
}

.tavsiya__content p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 50px;
  max-width: 700px;
  text-shadow: 0px 0px 8px #616161;
}

.tavsiya__content a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  background: #363CC7;
  -webkit-box-shadow: 0px 8px 16px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 8px 16px rgba(93, 95, 239, 0.2);
  border-radius: 8.02752px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 230px;
  height: 64px;
  position: relative;
  overflow: hidden;
}

.tavsiya__content a:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(rgba(92, 92, 92, 0.1)), to(rgba(122, 122, 122, 0.5)));
  background: linear-gradient(90deg, rgba(92, 92, 92, 0.1), rgba(122, 122, 122, 0.5));
  height: 70px;
  width: 160px;
  position: absolute;
  top: -3px;
  left: -250px;
  -webkit-transform: skewX(-45deg);
          transform: skewX(-45deg);
}

.tavsiya__content a:hover:before {
  left: 280px;
  -webkit-transition: .7s ease-in-out;
  transition: .7s ease-in-out;
}

.tavsiya__content div {
  z-index: 1;
}

.password__change .ant-modal-header {
  border-bottom: none;
  background: none;
}

.password__change .ant-modal-body p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 15px;
}

.password__change .ant-modal-body span {
  width: 300px;
  display: block;
  padding-bottom: 10px;
}

.password__change .ant-modal-body input {
  background: #FFFFFF;
  width: 300px;
  height: 50px;
  border: none;
  outline: none;
  margin-bottom: 25px;
  padding-left: 10px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.password__change .ant-modal-body input::-webkit-input-placeholder {
  text-align: center;
  font-size: 13px;
}

.password__change .ant-modal-body input:-ms-input-placeholder {
  text-align: center;
  font-size: 13px;
}

.password__change .ant-modal-body input::-ms-input-placeholder {
  text-align: center;
  font-size: 13px;
}

.password__change .ant-modal-body input::placeholder {
  text-align: center;
  font-size: 13px;
}

.password__change .ant-modal-footer {
  border-top: none;
}

.password__change .ant-modal-content {
  padding: 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #FFFFFF;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 20px;
}

.password__change .ant-modal-title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.password__change .ant-btn-default {
  display: none;
}

.password__change .ant-btn-primary {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706));
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%);
  border-radius: 10px;
  border: none;
  width: 300px;
  height: 60px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 35px;
  color: #FFFFFF;
  margin: 0 !important;
}

.password__change #error__path {
  -webkit-animation: err 6s linear infinite;
          animation: err 6s linear infinite;
}

@-webkit-keyframes err {
  0%, 100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

@keyframes err {
  0%, 100% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

.password__2 .ant-modal-body path {
  fill: #0BA7BF;
}

.password__2 .ant-btn-primary {
  background: #0BA7BF;
}

.password__4 .ant-modal-body path {
  fill: #1D794B;
}

.password__4 .ant-btn-primary {
  background: #1D794B;
}

.kabinet__bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
}

.kabinet__bg img {
  width: 110%;
  position: absolute;
  top: 0;
  height: 100%;
  left: -5%;
}

#kabinet__tavsiya1, #kabinet__tavsiya2, #kabinet__tavsiya4 {
  background: none;
}

#kabinet__tavsiya1 a, #kabinet__tavsiya2 a, #kabinet__tavsiya4 a {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 8.02752px 16.055px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 8.02752px 16.055px rgba(93, 95, 239, 0.2);
}

#kabinet__tavsiya1 span {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706));
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

#kabinet__tavsiya2 span {
  color: #0BA7BF;
}

#kabinet__tavsiya4 span {
  color: #1D794B;
}

#tsparticles {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.show__maktab {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.show__maktab .kabinet__wave {
  position: absolute;
  bottom: -5%;
  z-index: 1;
}

.show__maktab .kabinet__wave path {
  position: absolute;
  bottom: 0;
}

.show__oqituvchi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
}

.show__oqituvchi img {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.show__abiturient {
  position: absolute;
  top: 100px;
  width: 80%;
  height: calc(100% - 100px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.show__abiturient img:nth-child(1) {
  position: absolute;
  height: 100%;
  width: 100%;
}

.show__abiturient img:nth-child(2) {
  position: absolute;
}

#kabinet__navbar1, #kabinet__nav1 {
  background: #EB7300;
}

#kabinet__navbar2 {
  background: rgba(248, 248, 248, 0.2);
}

#kabinet__nav2 {
  background: #0BA7BF;
}

#kabinet__navbar4 {
  background: rgba(248, 248, 248, 0.2);
}

#kabinet__nav4 {
  background: #1D794B;
}

#kabinet__header1 .header__items .header__item {
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}

#kabinet__header1 .header__items .header__item a {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706));
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%);
  -webkit-box-shadow: 0px 4px 8px rgba(93, 95, 239, 0.2), inset 0px -4px 10px #B93900, inset 0px 6px 10px #FF8D3C;
          box-shadow: 0px 4px 8px rgba(93, 95, 239, 0.2), inset 0px -4px 10px #B93900, inset 0px 6px 10px #FF8D3C;
}

#kabinet__header2 {
  background: url(../../assets/images/Kabinet/abiturient_headerbg.webp);
  background-position: center;
  background-size: cover;
}

#kabinet__header2 .header__items .header__item b, #kabinet__header2 .header__items .header__item span {
  color: #0BA7BF;
}

#kabinet__header2 .header__items .header__item a {
  background: #0BA7BF;
  -webkit-box-shadow: 0px 3.25076px 6.50151px rgba(93, 95, 239, 0.2), inset 0px -3.25076px 8.12689px rgba(0, 131, 155, 0.2), inset 0px 4.87613px 8.12689px #18B4CC;
          box-shadow: 0px 3.25076px 6.50151px rgba(93, 95, 239, 0.2), inset 0px -3.25076px 8.12689px rgba(0, 131, 155, 0.2), inset 0px 4.87613px 8.12689px #18B4CC;
}

#kabinet__header4 {
  background: url(../../assets/images/Kabinet/oqituvchi_headerbg.webp);
}

#kabinet__header4 .header__items .header__item {
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

#kabinet__header4 .header__items .header__item a {
  background: #1D794B;
  -webkit-box-shadow: 0px 3.25076px 6.50151px rgba(93, 95, 239, 0.2), inset 0px -3.25076px 8.12689px rgba(49, 106, 78, 0.2), inset 0px 4.87613px 8.12689px #448B67;
          box-shadow: 0px 3.25076px 6.50151px rgba(93, 95, 239, 0.2), inset 0px -3.25076px 8.12689px rgba(49, 106, 78, 0.2), inset 0px 4.87613px 8.12689px #448B67;
}

#slider__title1 {
  border-bottom: 2px solid #F17120;
}

#slider__title11 {
  border-bottom: none;
}

#slider__title11::after {
  content: "";
  display: block;
  width: 280px;
  height: 20px;
  background: url(../../assets/images/Kabinet/pen1.png);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

#slider__title21 {
  border-bottom: none;
}

#slider__title21::after {
  content: "";
  display: block;
  width: 280px;
  height: 20px;
  background: url(../../assets/images/Kabinet/pen2.png);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

#slider__title31 {
  border-bottom: none;
}

#slider__title31::after {
  content: "";
  display: block;
  width: 280px;
  height: 20px;
  background: url(../../assets/images/Kabinet/pen3.png);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

#slider__title41 {
  border-bottom: none;
}

#slider__title41::after {
  content: "";
  display: block;
  width: 280px;
  height: 20px;
  background: url(../../assets/images/Kabinet/pen4.png);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

#slider__title2, #slider__title12,
#slider__title22, #slider__title32,
#slider__title42 {
  border-bottom: none;
}

#slider__title2::after, #slider__title12::after,
#slider__title22::after, #slider__title32::after,
#slider__title42::after {
  content: "";
  display: block;
  width: 280px;
  height: 20px;
  background: url(../../assets/images/Kabinet/line.png);
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

#slider__title4 {
  border-bottom: 2px solid #1D794B;
}

#slider__title14 {
  border-bottom: none;
}

#slider__title24 {
  border-bottom: none;
}

#slider__title34 {
  border-bottom: none;
}

#slider__title44 {
  border-bottom: none;
}

#kabinet__swiper1 .swiper__box .swiper__img .swiper__soni {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706));
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%);
}

#kabinet__swiper1 .swiper__box .swiper__btn {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706));
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%);
  -webkit-box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
}

#kabinet__swiper1 .swiper-pagination-bullet-active {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706)) !important;
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%) !important;
}

#kabinet__swiper1 .swiper-button-next::after,
#kabinet__swiper1 .swiper-button-prev::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#F17120), color-stop(51.04%, #F27221), to(#D75706));
  background: linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%);
}

#kabinet__swiper2 .swiper__box {
  background: #031600;
}

#kabinet__swiper2 .swiper__box .swiper__img .swiper__soni {
  background: #0BA7BF;
}

#kabinet__swiper2 .swiper__box .swiper__btn {
  background: #0BA7BF;
  -webkit-box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
}

#kabinet__swiper2 .swiper-pagination-bullet-active {
  background: #0BA7BF !important;
}

#kabinet__swiper2 .swiper-button-next::after,
#kabinet__swiper2 .swiper-button-prev::after {
  background: #0BA7BF;
}

#kabinet__swiper4 .swiper__box {
  background: #031600;
}

#kabinet__swiper4 .swiper__box .swiper__img .swiper__soni, #kabinet__swiper4 .swiper__box .swiper__img .swiper__top {
  background: #1D794B;
}

#kabinet__swiper4 .swiper__box .swiper__btn {
  background: #1D794B;
  -webkit-box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
          box-shadow: 0px 4.54487px 9.08975px rgba(93, 95, 239, 0.2);
}

#kabinet__swiper4 .swiper__info p {
  color: #478E69;
}

#kabinet__swiper4 .swiper__info path {
  stroke: #478E69;
}

#kabinet__swiper4 .swiper-pagination-bullet-active {
  background: #1D794B !important;
}

#kabinet__swiper4 .swiper-button-next::after,
#kabinet__swiper4 .swiper-button-prev::after {
  background: #1D794B;
}

.maktab__items {
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.maktab__items .maktab__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.maktab__items .maktab__item div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.maktab__items img {
  z-index: 1;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
}

.abiturient__items {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
  padding-bottom: 150px;
}

.m_i1 {
  -webkit-animation: maktab1 10s linear infinite alternate;
          animation: maktab1 10s linear infinite alternate;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

@-webkit-keyframes maktab1 {
  0% {
    -webkit-transform: translateY(-10px) scale(1.05) translateX(30px) rotate(-10deg);
            transform: translateY(-10px) scale(1.05) translateX(30px) rotate(-10deg);
  }
  50% {
    -webkit-transform: translateY(10px) translateX(30px) rotate(-15deg);
            transform: translateY(10px) translateX(30px) rotate(-15deg);
  }
  100% {
    -webkit-transform: translateY(-10px) scale(1.07) translateX(30px) rotate(-25deg);
            transform: translateY(-10px) scale(1.07) translateX(30px) rotate(-25deg);
  }
}

@keyframes maktab1 {
  0% {
    -webkit-transform: translateY(-10px) scale(1.05) translateX(30px) rotate(-10deg);
            transform: translateY(-10px) scale(1.05) translateX(30px) rotate(-10deg);
  }
  50% {
    -webkit-transform: translateY(10px) translateX(30px) rotate(-15deg);
            transform: translateY(10px) translateX(30px) rotate(-15deg);
  }
  100% {
    -webkit-transform: translateY(-10px) scale(1.07) translateX(30px) rotate(-25deg);
            transform: translateY(-10px) scale(1.07) translateX(30px) rotate(-25deg);
  }
}

.m_i2 {
  -webkit-animation: maktab2 4s linear infinite alternate;
          animation: maktab2 4s linear infinite alternate;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

@-webkit-keyframes maktab2 {
  0% {
    -webkit-transform: translateX(80px) rotate(-25deg);
            transform: translateX(80px) rotate(-25deg);
  }
  50% {
    -webkit-transform: translateX(70px) rotate(-20deg);
            transform: translateX(70px) rotate(-20deg);
  }
  100% {
    -webkit-transform: scale(1.05) translateX(70px) rotate(-25deg);
            transform: scale(1.05) translateX(70px) rotate(-25deg);
  }
}

@keyframes maktab2 {
  0% {
    -webkit-transform: translateX(80px) rotate(-25deg);
            transform: translateX(80px) rotate(-25deg);
  }
  50% {
    -webkit-transform: translateX(70px) rotate(-20deg);
            transform: translateX(70px) rotate(-20deg);
  }
  100% {
    -webkit-transform: scale(1.05) translateX(70px) rotate(-25deg);
            transform: scale(1.05) translateX(70px) rotate(-25deg);
  }
}

.m_i3 {
  -webkit-animation: maktab3 8s linear infinite alternate;
          animation: maktab3 8s linear infinite alternate;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

@-webkit-keyframes maktab3 {
  0% {
    -webkit-transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(0.9);
            transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(0.9);
  }
  50% {
    -webkit-transform: translateX(20px) translateY(-20px) rotate(-30deg) scale(1);
            transform: translateX(20px) translateY(-20px) rotate(-30deg) scale(1);
  }
  100% {
    -webkit-transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(1.1);
            transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(1.1);
  }
}

@keyframes maktab3 {
  0% {
    -webkit-transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(0.9);
            transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(0.9);
  }
  50% {
    -webkit-transform: translateX(20px) translateY(-20px) rotate(-30deg) scale(1);
            transform: translateX(20px) translateY(-20px) rotate(-30deg) scale(1);
  }
  100% {
    -webkit-transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(1.1);
            transform: translateX(20px) translateY(-20px) rotate(-25deg) scale(1.1);
  }
}

.m_i4 {
  -webkit-animation: maktab4 10s linear infinite alternate;
          animation: maktab4 10s linear infinite alternate;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

@-webkit-keyframes maktab4 {
  0% {
    -webkit-transform: translateX(80px) rotate(10deg);
            transform: translateX(80px) rotate(10deg);
  }
  50% {
    -webkit-transform: translateX(70px) rotate(0deg);
            transform: translateX(70px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(70px) rotate(15deg);
            transform: translateX(70px) rotate(15deg);
  }
}

@keyframes maktab4 {
  0% {
    -webkit-transform: translateX(80px) rotate(10deg);
            transform: translateX(80px) rotate(10deg);
  }
  50% {
    -webkit-transform: translateX(70px) rotate(0deg);
            transform: translateX(70px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(70px) rotate(15deg);
            transform: translateX(70px) rotate(15deg);
  }
}

.m_i5 {
  -webkit-animation: maktab5 7s linear infinite alternate;
          animation: maktab5 7s linear infinite alternate;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
  z-index: 2;
}

@-webkit-keyframes maktab5 {
  0% {
    -webkit-transform: translateX(170px) translateY(100px) rotate(17deg);
            transform: translateX(170px) translateY(100px) rotate(17deg);
  }
  50% {
    -webkit-transform: translateX(170px) translateY(120px) rotate(19deg);
            transform: translateX(170px) translateY(120px) rotate(19deg);
  }
  100% {
    -webkit-transform: translateX(170px) translateY(100px) rotate(17deg);
            transform: translateX(170px) translateY(100px) rotate(17deg);
  }
}

@keyframes maktab5 {
  0% {
    -webkit-transform: translateX(170px) translateY(100px) rotate(17deg);
            transform: translateX(170px) translateY(100px) rotate(17deg);
  }
  50% {
    -webkit-transform: translateX(170px) translateY(120px) rotate(19deg);
            transform: translateX(170px) translateY(120px) rotate(19deg);
  }
  100% {
    -webkit-transform: translateX(170px) translateY(100px) rotate(17deg);
            transform: translateX(170px) translateY(100px) rotate(17deg);
  }
}

.m_i6 {
  -webkit-animation: maktab6 7s linear infinite alternate;
          animation: maktab6 7s linear infinite alternate;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
}

@-webkit-keyframes maktab6 {
  0% {
    -webkit-transform: translateX(-50px) translateY(0px) rotate(-55deg);
            transform: translateX(-50px) translateY(0px) rotate(-55deg);
  }
  50% {
    -webkit-transform: translateX(-60px) translateY(0px) rotate(-65deg);
            transform: translateX(-60px) translateY(0px) rotate(-65deg);
  }
  100% {
    -webkit-transform: translateX(-50px) translateY(0px) rotate(-55deg);
            transform: translateX(-50px) translateY(0px) rotate(-55deg);
  }
}

@keyframes maktab6 {
  0% {
    -webkit-transform: translateX(-50px) translateY(0px) rotate(-55deg);
            transform: translateX(-50px) translateY(0px) rotate(-55deg);
  }
  50% {
    -webkit-transform: translateX(-60px) translateY(0px) rotate(-65deg);
            transform: translateX(-60px) translateY(0px) rotate(-65deg);
  }
  100% {
    -webkit-transform: translateX(-50px) translateY(0px) rotate(-55deg);
            transform: translateX(-50px) translateY(0px) rotate(-55deg);
  }
}

.m_i7 {
  -webkit-animation: maktab7 7s linear infinite alternate;
          animation: maktab7 7s linear infinite alternate;
  -webkit-animation-delay: calc(-1s * var(--i));
          animation-delay: calc(-1s * var(--i));
  z-index: 1;
}

@-webkit-keyframes maktab7 {
  0% {
    -webkit-transform: translateX(-90px) translateY(30px) rotate(20deg);
            transform: translateX(-90px) translateY(30px) rotate(20deg);
  }
  50% {
    -webkit-transform: translateX(-90px) translateY(30px) rotate(10deg);
            transform: translateX(-90px) translateY(30px) rotate(10deg);
  }
  100% {
    -webkit-transform: translateX(-90px) translateY(30px) rotate(20deg);
            transform: translateX(-90px) translateY(30px) rotate(20deg);
  }
}

@keyframes maktab7 {
  0% {
    -webkit-transform: translateX(-90px) translateY(30px) rotate(20deg);
            transform: translateX(-90px) translateY(30px) rotate(20deg);
  }
  50% {
    -webkit-transform: translateX(-90px) translateY(30px) rotate(10deg);
            transform: translateX(-90px) translateY(30px) rotate(10deg);
  }
  100% {
    -webkit-transform: translateX(-90px) translateY(30px) rotate(20deg);
            transform: translateX(-90px) translateY(30px) rotate(20deg);
  }
}

.search__working {
  background: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
}

.search__results {
  position: absolute;
  top: 70px;
  z-index: 100;
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  width: 250px;
  background: white;
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 0 0 5px;
  border-radius: 5px;
}

.search__results::-webkit-scrollbar {
  width: 0.5em;
}

.search__results::-webkit-scrollbar-track {
  background: none;
}

.search__results::-webkit-scrollbar-thumb {
  background-color: var(--i);
  border-radius: 2px;
}

.search__results a {
  color: #000 !important;
  margin: 3px 0;
  padding: 2px;
}

.search__results a:hover {
  text-shadow: none !important;
  background: #80808073;
}

.search__results img {
  margin-right: 5px;
  width: 50px;
  height: 35px;
}

@media (max-width: 1440px) {
  .kabinet__navbar {
    padding: 0 60px;
  }
  .tavsiya__content {
    padding: 0 0 0 100px;
  }
  .tavsiya__content img {
    width: 100%;
  }
  .show__oqituvchi img {
    height: 100%;
    width: auto;
  }
  .show__abiturient {
    width: 96%;
  }
  #kabinet__header2 h1, #kabinet__header2 p {
    max-width: 80%;
  }
}

@media (max-width: 1200px) {
  .show__abiturient img:nth-child(1) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .kabinet__header .header__items .header__item {
    max-width: none;
    padding: 2px;
    max-width: 260px;
  }
}

@media (max-width: 1024px) {
  .kabinet__navbar {
    padding: 0 20px;
  }
  .kabinet__navbar .kabinet__nav {
    -webkit-box-flex: 0.25;
        -ms-flex-positive: 0.25;
            flex-grow: 0.25;
  }
  .abiturient__items {
    display: none;
  }
  .kabinet__header {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .kabinet__header h1 {
    font-size: 40px;
    margin-top: 50px;
  }
  .kabinet__header p {
    font-size: 25px;
    max-width: 90%;
  }
  .tavsiya__content {
    padding: 0 0 0 50px;
  }
  .tavsiya__content h1 {
    font-size: 35px;
  }
  .tavsiya__content p {
    font-size: 21px;
    line-height: 25px;
  }
  .tavsiya__content a {
    font-size: 20px;
    width: 200px;
    height: 55px;
  }
  .kabinet__bg img {
    height: 100%;
    width: auto;
  }
  .maktab__items {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-top: 50px;
  }
}

@media (max-width: 768px) {
  .kabinet__navbar .kabinet__nav {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }
  .kabinet__navbar .kabinet__nav a {
    display: none;
  }
  .kabinet__navbar .kabinet__search {
    margin-right: 15px;
    right: 60px;
    width: 38px;
  }
  .kabinet__navbar .kabinet__search input {
    display: none;
  }
  .kabinet__navbar .kabinet__search .search__results {
    display: none;
  }
  .search__result {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.842);
    width: 100%;
    height: 0;
    z-index: 1000;
    -webkit-transition: 0.3s linear;
    transition: 0.3s linear;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .search__result .search__input {
    background: white;
    height: 40px;
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
  }
  .search__result .search__input input {
    width: 350px;
    height: 40px;
    border: none;
    outline: none;
    background: white;
    padding-left: 15px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
  }
  .search__result .search__input button {
    height: 40px;
    width: 40px;
    border: none;
  }
  .search__results {
    width: 414px;
    top: 50px;
    border-radius: 0 0 5px 5px;
    max-height: calc(100vh - 50px);
  }
  .kabinet__search__show {
    height: 100%;
  }
  .k__mobile .a1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .kabinet__header {
    padding: 135px 20px 35px 20px;
  }
  .kabinet__header .header__items {
    grid-gap: 15px;
  }
  .kabinet__slider {
    padding: 50px 30px;
  }
  .kabinet__slider .slider__title {
    font-size: 25px;
  }
  .tavsiya__content {
    height: 400px;
  }
  .tavsiya__content h1 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 40px;
  }
  .tavsiya__content p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .password__change .ant-modal-title {
    font-size: 25px;
    line-height: 35px;
  }
  .password__change .ant-modal-body svg {
    width: 180px;
  }
  .password__change .ant-btn-primary {
    font-size: 18px;
    width: 280px;
    height: 55px;
  }
  .password__change .ant-modal-body input {
    width: 280px;
    height: 45px;
  }
  .show__abiturient img:nth-child(1) {
    display: none;
  }
  #kabinet__header2 h1, #kabinet__header2 p {
    max-width: 95%;
  }
}

@media (max-width: 660px) {
  .kabinet__header h1 {
    font-size: 35px;
    margin-top: 25px;
  }
  .kabinet__header p {
    font-size: 24px;
  }
  .kabinet__header .header__items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .kabinet__header .header__items .header__item {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.2fr 2fr;
        grid-template-columns: 1.2fr 2fr;
        grid-template-areas: "img1 name1" "img1 text1" "img1 btn1";
    max-width: none;
    grid-gap: 0 10px;
    justify-items: center;
  }
  .kabinet__header .header__items .header__item .header__item__img {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    grid-area: img1;
    height: 100%;
    max-height: 220px;
  }
  .kabinet__header .header__items .header__item .header__item__img img {
    height: 100%;
    width: auto;
  }
  .kabinet__header .header__items .header__item a {
    width: 50%;
  }
  .tavsiya__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: auto;
    padding: 30px;
    grid-gap: 30px;
  }
  .tavsiya__content div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  .show__maktab .kabinet__wave {
    display: none;
  }
  .kabinet__bg {
    display: none;
  }
  #kabinet__tavsiya1 {
    background: #EB7300 url(../../assets/images/Kabinet/maktab_item.png);
    background-position: center;
    background-size: cover;
  }
  #kabinet__tavsiya2 {
    background: #0BA7BF url(../../assets/images/Kabinet/maktab_item.png);
    background-position: center;
    background-size: cover;
  }
  #kabinet__tavsiya4 {
    background: #1D794B url(../../assets/images/Kabinet/maktab_item.png);
    background-position: center;
    background-size: cover;
  }
  .maktab__items {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 470px) {
  .kabinet__header h1 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 40px;
  }
  .kabinet__header p {
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .kabinet__header .header__items .header__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .kabinet__header .header__items .header__item .header__item__img {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
    max-width: none;
  }
  .kabinet__header .header__items .header__item .header__item__img img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 425px) {
  .kabinet__navbar .kabinet__logo img {
    height: 60px;
  }
  .kabinet__header .header__items .header__item {
    width: 300px;
  }
  .kabinet__slider {
    padding: 20px;
  }
  .kabinet__slider .slider__title {
    font-size: 21px;
  }
  .kabinet__swiper {
    padding: 0 0px 60px 0px !important;
  }
  .kabinet__swiper .swiper-button-next,
  .kabinet__swiper .swiper-button-prev {
    width: 40px;
    height: 40px;
    bottom: 0;
    top: auto;
  }
  .kabinet__swiper .swiper-button-next {
    right: 30%;
  }
  .kabinet__swiper .swiper-button-prev {
    left: 30%;
  }
  .kabinet__swiper .swiper-pagination-bullets-dynamic {
    display: none;
  }
  .tavsiya__content h1 {
    font-size: 27px;
  }
  .tavsiya__content p {
    font-size: 16px;
  }
  .tavsiya__content a {
    font-size: 16px;
    width: 160px;
    height: 45px;
  }
  .password__change .ant-modal-content {
    padding: 30px 0;
  }
  #kabinet__header1 .header__items .header__item {
    background: rgba(255, 255, 255, 0.9);
  }
  .maktab__items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  @-webkit-keyframes maktab4 {
    0% {
      -webkit-transform: translateX(-40px) rotate(10deg);
              transform: translateX(-40px) rotate(10deg);
    }
    50% {
      -webkit-transform: translateX(-40px) rotate(0deg);
              transform: translateX(-40px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateX(-40px) rotate(15deg);
              transform: translateX(-40px) rotate(15deg);
    }
  }
  @keyframes maktab4 {
    0% {
      -webkit-transform: translateX(-40px) rotate(10deg);
              transform: translateX(-40px) rotate(10deg);
    }
    50% {
      -webkit-transform: translateX(-40px) rotate(0deg);
              transform: translateX(-40px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateX(-40px) rotate(15deg);
              transform: translateX(-40px) rotate(15deg);
    }
  }
  @-webkit-keyframes maktab5 {
    0% {
      -webkit-transform: translateX(-10px) translateY(100px) rotate(17deg);
              transform: translateX(-10px) translateY(100px) rotate(17deg);
    }
    50% {
      -webkit-transform: translateX(-10px) translateY(120px) rotate(19deg);
              transform: translateX(-10px) translateY(120px) rotate(19deg);
    }
    100% {
      -webkit-transform: translateX(-10px) translateY(100px) rotate(17deg);
              transform: translateX(-10px) translateY(100px) rotate(17deg);
    }
  }
  @keyframes maktab5 {
    0% {
      -webkit-transform: translateX(-10px) translateY(100px) rotate(17deg);
              transform: translateX(-10px) translateY(100px) rotate(17deg);
    }
    50% {
      -webkit-transform: translateX(-10px) translateY(120px) rotate(19deg);
              transform: translateX(-10px) translateY(120px) rotate(19deg);
    }
    100% {
      -webkit-transform: translateX(-10px) translateY(100px) rotate(17deg);
              transform: translateX(-10px) translateY(100px) rotate(17deg);
    }
  }
  @-webkit-keyframes maktab6 {
    0% {
      -webkit-transform: translateX(-150px) translateY(0px) rotate(-55deg);
              transform: translateX(-150px) translateY(0px) rotate(-55deg);
    }
    50% {
      -webkit-transform: translateX(-160px) translateY(0px) rotate(-65deg);
              transform: translateX(-160px) translateY(0px) rotate(-65deg);
    }
    100% {
      -webkit-transform: translateX(-150px) translateY(0px) rotate(-55deg);
              transform: translateX(-150px) translateY(0px) rotate(-55deg);
    }
  }
  @keyframes maktab6 {
    0% {
      -webkit-transform: translateX(-150px) translateY(0px) rotate(-55deg);
              transform: translateX(-150px) translateY(0px) rotate(-55deg);
    }
    50% {
      -webkit-transform: translateX(-160px) translateY(0px) rotate(-65deg);
              transform: translateX(-160px) translateY(0px) rotate(-65deg);
    }
    100% {
      -webkit-transform: translateX(-150px) translateY(0px) rotate(-55deg);
              transform: translateX(-150px) translateY(0px) rotate(-55deg);
    }
  }
  .search__result .search__input {
    width: calc(100% - 10px);
  }
  .search__result .search__input input {
    width: 100%;
  }
  .search__results {
    width: calc(100% - 10px);
  }
}

@media (max-width: 375px) {
  .kabinet__header {
    padding: 120px 10px 30px 10px;
  }
  .kabinet__header h1 {
    margin-top: 0;
  }
  .kabinet__slider {
    padding: 15px 10px;
  }
  .kabinet__slider .slider__title {
    font-size: 18px;
  }
  .tavsiya__content {
    padding: 15px;
  }
  .tavsiya__content h1 {
    font-size: 24px;
  }
  .tavsiya__content p {
    line-height: 20px;
  }
  .password__change .ant-modal-header {
    padding: 10px;
  }
  .password__change .ant-modal-title {
    font-size: 20px;
    line-height: 26px;
  }
  .password__change .ant-modal-body {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .maktab__items {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

@media (max-width: 335px) {
  .kabinet__navbar .kabinet__search input {
    width: 185px;
  }
  .kabinet__navbar .kabinet__search__show {
    width: 230px;
  }
}

@media (max-width: 320px) {
  .kabinet__header h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .kabinet__header p {
    font-size: 18px;
    line-height: 24px;
  }
}
