.HomeVideo__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 200px;
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  background: url(../../assets/images/HomeVideo/video_poster.webp);
  background-position: center;
  background-size: cover;
  position: relative;
}

.HomeVideo__container .react-player__preview {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
  padding: 0 300px;
}

.dhomevid {
  width: 100%;
  background: radial-gradient(50% 0% at 50% 50%, #0010A0 0%, #000852 100%);
}

.HomeVideo__text {
  max-width: 1000px;
}

.HomeVideo__text h1 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 57.6471px;
  line-height: 72px;
  color: #FFFFFF;
  margin-bottom: 50px;
}

.HomeVideo__text p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 200;
  font-size: 44.8366px;
  line-height: 56px;
  color: #FFFFFF;
}

.back__video {
  height: 800px;
  background: radial-gradient(50% 50% at 50% 50%, #0010A0 0%, #000852 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

.back__video video {
  background: radial-gradient(50% 50% at 50% 50%, #0010A0 0%, #000852 100%);
  z-index: 1000;
}

.play_btn {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #FFFFFF;
  -webkit-box-shadow: 2px 20px 60px rgba(61, 155, 185, 0.1);
          box-shadow: 2px 20px 60px rgba(61, 155, 185, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  cursor: pointer;
}

.play_btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (max-width: 1570px) {
  .HomeVideo__container {
    padding: 0 150px;
  }
}

@media (max-width: 1440px) {
  .HomeVideo__container {
    padding: 0 100px;
  }
  .HomeVideo__text {
    max-width: 75%;
  }
  .HomeVideo__text h1 {
    font-size: 45px;
    line-height: 50px;
  }
  .HomeVideo__text p {
    font-size: 35px;
    line-height: 45px;
  }
}

@media (max-width: 1024px) {
  .HomeVideo__container {
    min-height: 600px;
    padding: 0 50px;
  }
  .HomeVideo__text h1 {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .HomeVideo__text p {
    font-size: 35px;
    line-height: 40px;
  }
  .play_btn {
    width: 150px;
    height: 150px;
  }
  .play_btn img {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 768px) {
  .HomeVideo__text {
    max-width: 100%;
    text-align: center;
  }
  .HomeVideo__text h1 {
    font-size: 30px;
  }
  .HomeVideo__text p {
    font-size: 25px;
  }
  .back__video {
    height: 600px !important;
  }
  .HomeVideo__container {
    padding: 0 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 625px) {
  .HomeVideo__text h1 {
    font-size: 25px;
    line-height: 33px;
  }
  .HomeVideo__text p {
    font-size: 20px;
    line-height: 33px;
  }
  .HomeVideo__container .react-player__preview {
    padding: 0 100px;
  }
}

@media (max-width: 425px) {
  .HomeVideo__container .react-player__preview {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .back__video {
    height: 400px !important;
  }
  .play_btn {
    width: 100px;
    height: 100px;
  }
  .play_btn img {
    width: 30px;
    height: 30px;
  }
}
