@import url("https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap");
.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 1920px;
  width: 100%;
  padding: 60px 200px;
}

.footer__kabinet {
  background: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1920px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 0px 200px 60px 0px;
  height: 400px;
}

.footer__kabinet .footer__left, .footer__kabinet .footer__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 200px;
  z-index: 1;
}

.footer__kabinet .footer__center {
  z-index: 1;
}

.footer__image {
  -webkit-transform: translateY(60px);
          transform: translateY(60px);
  width: 400px;
  height: 450px;
  overflow: hidden;
}

#footer__image1 {
  background: url(../../assets/images/Kabinet/footer_maktab1.png);
  background-position: top right;
  background-repeat: no-repeat;
}

#footer__image2 {
  background: url(../../assets/images/Kabinet/footer_abiturient.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#footer__image4 {
  background: url(../../assets/images/Kabinet/footer_oqituvchi.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.footer__bg {
  position: absolute;
  width: 100%;
  overflow: hidden;
  bottom: 0;
}

.footer__bg img {
  width: 100%;
  height: 400px;
}

.footer__left p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 18.3028px;
  line-height: 23px;
  max-width: 230px;
  color: #ffffffc7;
  margin-top: 10px;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

.footer__center {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 0 100px;
}

.footer__center a {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: #FFFFFF;
  margin: 15px 0;
  max-width: 235px;
}

.footer__right p {
  font-family: 'Play';
  font-style: normal;
  font-weight: 700;
  font-size: 36.2951px;
  line-height: 42px;
  color: #FFFFFF;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  margin-left: 5px;
}

.footer__right form {
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer__right form button {
  width: 150px;
  height: 55px;
  background: -webkit-gradient(linear, left top, left bottom, from(#03F5FF), to(#02B6EB));
  background: linear-gradient(180deg, #03F5FF 0%, #02B6EB 100%);
  border-radius: 10px;
  border: none;
  outline: none;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.footer__right form input {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 30px;
  border: none;
  outline: none;
  background: none;
  padding-left: 10px;
  width: 150px;
}

.dc__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.social__sets {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media (max-width: 1440px) {
  .footer {
    padding: 60px 100px;
  }
  .footer__kabinet {
    padding: 0 20px 60px 0 !important;
  }
  .footer__kabinet .footer__center {
    grid-gap: 0 30px;
  }
}

@media (max-width: 1250px) {
  .footer__kabinet .footer__center a {
    font-size: 18px;
    margin: 10px 0;
  }
  .footer__kabinet .dc__logo img {
    height: 50px;
  }
  .footer__kabinet .dc__logo p {
    font-size: 29px;
    line-height: 25px;
  }
  .footer__image {
    width: 300px;
    height: 300px;
  }
  #footer__image1 {
    background-size: cover;
  }
  .footer__bg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer__bg img {
    width: auto;
  }
}

@media (max-width: 1128px) {
  .footer {
    padding: 50px;
  }
}

@media (max-width: 1024px) {
  .footer__kabinet {
    height: 300px;
  }
  .footer__bg img {
    height: 340px;
  }
  .footer__left p {
    font-size: 15px;
  }
  .footer__center {
    margin-left: 20px;
    grid-gap: 0 30px;
  }
  .footer__center a {
    font-size: 18px;
  }
}

@media (max-width: 930px) {
  .footer__bg {
    display: none;
  }
  .footer__image {
    -webkit-transform: translateY(0px) translateX(-30px);
            transform: translateY(0px) translateX(-30px);
  }
  .footer__kabinet {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 30px 0 30px !important;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    height: auto;
    grid-gap: 10px;
        grid-template-areas: "f_logo f_link" "f_img f_dc";
  }
  .footer__kabinet .social__sets {
    grid-gap: 20px;
  }
  .footer__kabinet .footer__left {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer__kabinet .footer__left img {
    height: 120px;
  }
  .footer__kabinet .footer__left p {
    text-align: center;
  }
  .footer__right {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .footer__image {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: f_img;
  }
  #footer__kabinet1 {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(22.11%, #e19c00cc), to(#be4100cc)), url(../../assets/images/Kabinet/footer.webp);
    background: linear-gradient(180deg, #e19c00cc 22.11%, #be4100cc 100%), url(../../assets/images/Kabinet/footer.webp);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
  }
  #footer__kabinet2 {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(22.11%, #18b4cccc), to(#0ba7bfcc)), url(../../assets/images/Kabinet/footer.webp);
    background: linear-gradient(180deg, #18b4cccc 22.11%, #0ba7bfcc 100%), url(../../assets/images/Kabinet/footer.webp);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
  }
  #footer__kabinet4 {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(22.11%, #47a375cc), to(#025e30cc)), url(../../assets/images/Kabinet/footer.webp);
    background: linear-gradient(180deg, #47a375cc 22.11%, #025e30cc 100%), url(../../assets/images/Kabinet/footer.webp);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
  }
}

@media (max-width: 900px) {
  .footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer__left p {
    margin-left: 30px;
  }
  .footer__center {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    margin: 20px 0;
  }
  .footer__kabinet .footer__center {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 30px;
  }
  .footer__kabinet .footer__left {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer__kabinet .footer__left img {
    height: 100px;
  }
  .footer__kabinet .footer__center {
    grid-gap: 0 10px;
  }
  .footer__kabinet .footer__center a {
    font-size: 16px;
  }
  #footer__image1 {
    background: url(../../assets/images/Kabinet/footer_maktab.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .footer__image {
    width: 200px;
    height: 200px;
  }
  .footer__kabinet {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "f_logo f_logo f_link f_link" "f_img f_dc f_dc f_dc";
  }
  .footer__kabinet .footer__left {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: f_logo;
  }
  .footer__kabinet .footer__center {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-area: f_link;
  }
  .footer__kabinet .footer__right {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
    grid-area: f_dc;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 15px;
  }
  .footer__kabinet .social__sets {
    margin-top: 0;
  }
}

@media (max-width: 650px) {
  .footer__center {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .footer__center a {
    text-align: center;
  }
  .footer__kabinet {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: "f_logo" "f_link" "f_dc" "f_img";
    justify-items: center;
  }
  .footer__image {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@media (max-width: 450px) {
  .footer__kabinet .footer__right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer__kabinet .social__sets {
    margin-top: 20px;
    grid-gap: 10px;
  }
}

@media (max-width: 425px) {
  .footer__left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer__left p {
    margin: 20px 0 0 0;
    text-align: center;
  }
  .footer__center {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .footer__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .social__sets a {
    margin: 0 10px;
  }
}

@media (max-width: 375px) {
  .footer__kabinet {
    padding: 20px 20px 0 20px !important;
  }
}

@media (max-width: 320px) {
  .footer {
    padding: 0;
  }
  .footer__right form button {
    width: 140px;
    font-size: 14px;
  }
}
