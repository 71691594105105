.load1{
  height: 200px;
  width: 200px;
  position: relative;
  margin: 10px;
  border: 1px solid black;
}
.loadings{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.npm__link{
  display: flex;
  align-items: center;
  justify-content: center;
}
.npm__link a {
  font-size: 25px;
}